import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from './shared/helpers/localSortage';
import { environment } from "../environments/environment";
import { MessagingService } from './shared/services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'teslimadmin';
  message;
  logged = false;
  token: any = null;
  messages: any[] = [];
  constructor(private router: Router, private messagingService: MessagingService) { }

  ngOnInit() {

    if (LocalStorage.getItem('Squares_user') != null) {
      this.logged = true;
    } else {
      this.logged = false;
      this.router.navigate(['/login']);
    }

  }
  requestPermission() {

  }
  listen() {

  }
}
