import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { MdcDialog } from "@angular-mdc/web";
import { Loader } from "../../../shared/helpers/loader";
import { ProduitEditModalComponent } from "../../../shared/components/produit-edit-modal/produit-edit-modal.component";
import { STATE } from "../../../shared/model/codes";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { OrderDetailsModal } from "../../../shared/components/order-details-modal/order-details-modal";
import { DialogBox } from "src/app/shared/helpers/dialogBox";

@Component({
  selector: "app-marchand-details",
  templateUrl: "./marchand-details.component.html",
  styleUrls: ["./marchand-details.component.scss"],
})
export class MarchandDetailsComponent implements OnInit {
  article_type = {
    id: "1",
    type: "",
  };
  produit: any = {};
  service: any = {};
  commandes = [];
  stocks = [];
  actualWeek: any[] = [];

  actualDate: any;
  date_debut = "2019/12/12";
  date_fin = "2019/12/12";
  display_date_debut = "2019/12/12";
  display_date_fin = "2019/12/12";
  public pieChartType = "bar";
  public pieChartLegend = true;
  // public pieChartData: number[] = [300, 500, 100, 450];
  public pieChartLabels: any[] = [];
  pieData = [{ data: [0], label: "Siri" }];
  articleData = {
    ventes_total: 0,
    ventes_moyenne: 0,
    nombre_ventes: 0,
    data: [],
  };
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  articles = [];
  summary = {
    nombre_produits: 0,
    ventes_total: 0,
    commandes_annule: 0,
    commandes_ok: 0,
  };
  popup: Window = null;
  user = null;
  constructor(
    private route: ActivatedRoute,
    private core: CorePrototype,
    private dialog: MdcDialog
  ) {}

  ngOnInit() {
    Loader.load();
    let token = this.route.snapshot.paramMap.get("id");
    this.actualDay(new Date(), {});
    var tmp = token.split("-");
    this.article_type.id = tmp[1];
    this.article_type.type = tmp[0];
    this.date_debut = this.actualWeek[0].dayDate;
    this.date_fin = this.actualWeek[6].dayDate;
    this.display_date_debut = this.actualWeek[0].dayDate;
    this.display_date_fin = this.actualWeek[6].dayDate;
    this.core
      .get(ENDPOINTS.get_institute_products_in_squares, {
        id_etablissement: this.ets.id_etablissement,
      })
      .then((data: any) => {
        // this.term.replace(' ','_')
        console.log(data);
        data.body.forEach((square) => {
          square.produits.forEach((element) => {
            element.show = 1;
            element.animation = false;
            element.tmp_nom = element.nom_produit.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ""
            );
            element.sp_nom =
              element.tmp_nom.replace(" ", "_") +
              "_" +
              element.id_produit +
              "_1";
            element.sp_nom = element.sp_nom.split(" ").join("");
          });
        });
        this.articles = data.body;
        console.log(this.articles);
        Loader.stopLoading();
      });
    this.core
      .get(ENDPOINTS.get_orders_by_institute, {
        id_etablissement: this.ets.id_etablissement,
      })
      .then((data: any) => {
        data.body.forEach((element) => {
          element.date_creation_commande = new Date(
            parseInt(element.date_creation_commande)
          );
        });
        this.commandes = data.body;
        this.commandes.forEach((element: any) => {
          element.etat_commande_int = element.etat_commande;
          element.etat_commande = STATE[element.etat_commande];
          element.show = 1;
        });
        this.commandes.reverse();
        Loader.stopLoading();
      });
    // this.getGraph();
    this.core
      .get(ENDPOINTS.get_user, {
        id_utilisateur: this.ets.id_utilisateur,
      })
      .then((data: any) => {
        this.user = data.body;
      });
  }
  getGraph() {
    this.core
      .get(ENDPOINTS.get_summary, {
        id_etablissement: this.ets.id_etablissement,
        date_debut: new Date(this.date_debut).getTime(),
        date_fin: new Date(this.date_fin).getTime(),
      })
      .then((data: any) => {
        this.summary = data.body;
      });
  }
  editOrder(order) {
    // ets.mode = '1';
    const dialogRef = this.dialog.open(OrderDetailsModal, {
      data: order,
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal === "close" || resultModal === null) {
        // alert('Closed');
        return;
      }
    });
  }
  edit() {
    if (this.article_type.type === "service") {
      this.service.mode = "1";
      const dialogRef = this.dialog.open(ProduitEditModalComponent, {
        data: this.service,
      });

      dialogRef.afterClosed().subscribe((resultModal: any) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          this.service = resultModal;
        }
      });
    } else {
      this.produit.mode = "1";
      const dialogRef = this.dialog.open(ProduitEditModalComponent, {
        data: this.produit,
      });

      dialogRef.afterClosed().subscribe((resultModal: any) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.produit = resultModal
        }
      });
    }
  }
  onChange(event) {
    console.log(event);

    if (this.article_type.type === "service") {
      var local_article: any = {
        id_service: this.service.id_service,
      };
      // if (event.checked === false) {
      //   this.service.etat_service = "0";
      // } else {
      //   this.service.etat_service = "1";
      // }

      if (this.service.can_be_reserved_bool == true) {
        local_article.can_be_reserved = 1;
      } else {
        local_article.can_be_reserved = 0;
      }
      if (this.service.can_be_delivered_bool == true) {
        local_article.can_be_delivered = 1;
      } else {
        local_article.can_be_delivered = 0;
      }
      if (this.service.for_kitchen == true) {
        local_article.for_kitchen = 1;
      } else {
        local_article.for_kitchen = 0;
      }
      if (this.service.etat_service_bool == true) {
        local_article.etat_service = 1;
      } else {
        local_article.etat_service = 0;
      }

      Loader.load();
      this.core
        .post(ENDPOINTS.post_update_service, local_article)
        .then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_service == "1") {
            data.body.etat_service_bool = true;
          } else {
            data.body.etat_service_bool = false;
          }
          this.service = data.body;
          // this.dialogRef.close(data.body);
        });
    } else {
      // if (event.checked === false) {
      //   this.produit.etat_produit = "0";
      // } else {
      //   this.produit.etat_produit = "1";
      // }
      var local_article: any = {
        id_produit: this.produit.id_produit,
      };
      // if (event.checked === false) {
      //   this.service.etat_service = "0";
      // } else {
      //   this.service.etat_service = "1";
      // }

      if (this.produit.can_be_reserved_bool == true) {
        local_article.can_be_reserved = 1;
      } else {
        local_article.can_be_reserved = 0;
      }
      if (this.produit.can_be_delivered_bool == true) {
        local_article.can_be_delivered = 1;
      } else {
        local_article.can_be_delivered = 0;
      }
      if (this.produit.for_kitchen == true) {
        local_article.for_kitchen = 1;
      } else {
        local_article.for_kitchen = 0;
      }
      if (this.produit.etat_produit_bool == true) {
        local_article.etat_produit = 1;
      } else {
        local_article.etat_produit = 0;
      }
      if (this.produit.can_visible_on_marketplace_bool == true) {
        local_article.can_visible_on_marketplace = 1;
      } else {
        local_article.can_visible_on_marketplace = 0;
      }
      if (this.produit.is_local_product_bool == true) {
        local_article.is_local_product = 1;
      } else {
        local_article.is_local_product = 0;
      }
      Loader.load();
      // tslint:disable-next-line: max-line-length
      this.core
        .post(ENDPOINTS.post_update_product, local_article)
        .then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_produit == "1") {
            data.body.etat_produit_bool = true;
          } else {
            data.body.etat_produit_bool = false;
          }
          if (data.body.can_visible_on_marketplace == "1") {
            data.body.can_visible_on_marketplace_bool = true;
          } else {
            data.body.can_visible_on_marketplace_bool = false;
          }
          if (data.body.is_local_product == "1") {
            data.body.is_local_product_bool = true;
          } else {
            data.body.is_local_product_bool = false;
          }
          this.produit = data.body;
          // this.dialogRef.close(data.body);
        });
    }
  }
  deleteArticle(article) {
    if (article.id_service) {
      DialogBox.confirmationMessage(this.dialog, {
        title: "Confirmation",
        body: "Etes vous sur de vouloir supprimer ce service",
      }).then((result: any) => {
        if (result) {
          this.service
            .post(ENDPOINTS.post_update_service, {
              id_service: article.id_service,
              is_deleted_service: "1",
            })
            .then((data: any) => {
              console.log(data);
              var index = this.articles.findIndex(
                (item) =>
                  item.id_departement ==
                  article.categorie_services[0].id_departement
              );

              var index1 = this.articles[index].data.services.findIndex(
                (item) => item.id_service == article.id_service
              );
              this.articles[index].data.services.splice(index1, 1);
            });
        } else {
        }
      });
    } else {
      DialogBox.confirmationMessage(this.dialog, {
        title: "Confirmation",
        body: "Etes vous sur de vouloir supprimer ce produit",
      }).then((result: any) => {
        if (result) {
          this.core
            .post(ENDPOINTS.post_update_product, {
              id_produit: article.id_produit,
              is_deleted_produit: "1",
            })
            .then((data: any) => {
              console.log(data);
              var index = this.articles.findIndex(
                (item) => item.id_caree == article.id_caree
              );

              var index1 = this.articles[index].produits.findIndex(
                (item) => item.id_produit == article.id_produit
              );
              this.articles[index].produits.splice(index1, 1);
            });
        } else {
        }
      });
    }
  }
  updateProductStock(stock) {
    // console.log(stock);
    stock.mode = "1";

    // var dialogRef = this.dialog.open(StockModalComponent, {
    //   data: stock,
    // });

    // dialogRef.afterClosed().subscribe((resultModal: any) => {
    //   console.log(resultModal);
    //   if (resultModal !== "close" || resultModal !== null) {
    //     // alert('Closed');
    //     // this.entreprises.push(resultModal);
    //     var index = this.stocks.findIndex(
    //       (item) => item.id_produit == resultModal.id_produit
    //     );
    //     this.stocks[index] = resultModal;
    //     this.produit.quantite_en_stock =
    //       parseInt(this.produit.quantite_en_stock) +
    //       parseInt(this.stocks[index].final);
    //     delete this.stocks[index].final;
    //     return;
    //   }
    // });
  }
  actualDay(curr, option) {
    // var tmp=[];
    // var curr2 = new Date(curr);
    if (typeof curr !== "object") {
      curr = new Date(curr);
      this.actualDate = this.convertDate(
        new Date(curr.setDate(curr.getDate()))
      );
    }
    curr.setDate(curr.getDate() - curr.getDay());

    for (var i = 0; i < 7; i++) {
      this.actualWeek[i] = { dayDate: this.convertDate(new Date(curr)) };
      curr.setDate(curr.getDate() + 1);
      var localDate = new Date(this.actualWeek[i].dayDate);
      var stamp = localDate.getTime();
      this.actualWeek[i].timestamp = stamp;
    }
  }
  convertDate(date) {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  updateGraph() {
    Loader.loadBack();
    this.getGraph();
  }
  openOriginal() {
    this.popup = window.open(
      "http://accountsquares.seeds.cm/home",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=no,top=0,left=0,width=1500,height=750"
    );
    this.popup.postMessage(JSON.stringify(this.ets), "*");
    this.popup.postMessage(JSON.stringify(this.user), "*");
  }
}
