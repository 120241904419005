import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { MethodUtils } from "../../../shared/helpers/methodUtils";
import { Router } from "@angular/router";
import { _ } from "underscore";
import { LocalStorage } from "../../helpers/localSortage";
import { CorePrototype } from "src/app/core/services/core.prototype";
import { FLAG } from "../../model/flag";
import { DialogBox } from "../../helpers/dialogBox";
import { User } from "../../../shared/helpers/user";
import { BASE_URL } from "../../model/codes";
import { MdcDialog } from "@angular-mdc/web";
// import { StockGeneralModalComponent } from "../stock-general-modal/stock-general-modal.component";
// import { FournisseurModalComponent } from "../fournisseur-modal/fournisseur-modal.component";
// import { ViewEncapsulation } from "@angular/core";
import { CustomerService } from "../customer-service/customer-service";

declare var jQuery: any;
declare var M: any;

@Component({
  selector: "sidebar-component",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  user: any = {};
  open = false;
  destinations = [
    {
      title: "",
      menus: [
        {
          label: "Tableau de bord",
          icon: "mdiMonitorDashboard",
          route: "home",
          index: 4,
        },
        {
          label: "Enregistrer une vente",
          icon: "mdiCartOutline",
          route: "facturation",
          index: 2,
        },

        {
          label: "Produits / Services",
          icon: "mdiBriefcaseVariant",
          route: "articles",
          index: 6,
        },
        {
          label: "Promotions",
          icon: "mdiSale",
          route: "promotions",
          index: 20,
        },
        {
          label: "Options de livraison",
          icon: "mdiTruck",
          route: "livraison",
          index: 17,
        },
        {
          label: "Bulk upload",
          icon: "mdiCloudUpload",
          route: "bulk_upload",
          index: 8,
        },
        {
          label: "Factures proforma",
          icon: "mdiNewspaper",
          route: "proforma",
          index: 28,
        },
      ],
    },
    {
      title: "Activites",
      menus: [
        {
          label: "Historique des ventes",
          icon: "mdiUpdate",
          route: "historique",
          index: 21,
        },
        {
          label: "Mes commandes",
          icon: "mdiOrderBoolDescendingVariant",
          route: "commandes",
          index: 31,
        },
      ],
    },
    {
      title: "Parametres",
      menus: [
        {
          label: "Mon Compte",
          icon: "mdiAccount",
          route: "params",
          index: 22,
        },
        {
          label: "Mon Etablissement",
          icon: "mdiDomain",
          route: "settings",
          index: 29,
        },
      ],
    },
  ];

  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private router: Router,
    private service: CorePrototype,
    private dialog: MdcDialog
  ) {}
  ngOnInit() {
    this.user = User.getUser();
    this.hideMenu();
    // console.log(this.router.url);
    // console.log(this.ets);
  }
  ngAfterViewInit() {
    var that = this;

    // this.destinations.forEach((element: any) => {
    //   // console.log(element.route);
    //   if ("/" + element.route === this.router.url) {
    //     jQuery("." + element.route).addClass("mdc-list-item--activated");
    //     // console.log('#mdc-list-item-' + element.index);
    //   }
    // });
  }
  openMenu() {
    if (this.open === false) {
      this.open = true;
      this.showMenu();
    } else {
      this.open = false;
      // jQuery(".menutext").hide();
      this.hideMenu();
    }
  }
  showMenu() {
    jQuery("#menu").css({
      transform: "translateX(1%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "1",
      display: "block",
    });
  }
  hideMenu() {
    jQuery("#menu").css({
      transform: "translateX(-100%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "0",
      display: "none",
    });
  }
  goToMenu(fragment) {
    document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
  }
  routeMenu(item) {
    console.log(item);
    var dialogRef = null;
    switch (item.route) {
      case "add_stock":
        // dialogRef = this.dialog.open(StockGeneralModalComponent, {
        //   data: { mode: "0" },
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        // if (resultModal !== "close" || resultModal !== null) {
        //   if (resultModal.id_etablissement) {
        //     this.entreprises.push(resultModal);
        //     return;
        //   }
        // }
        // });
        break;
      case "declare_loss":
        this.router.navigateByUrl("sorties_all");
        break;
      case "add_supplier":
        this.router.navigateByUrl("fournisseurs");

        // dialogRef = this.dialog.open(FournisseurModalComponent, {
        //   data: { mode: "0" }
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        //   // if (resultModal !== "close" || resultModal !== null) {
        //   //   if (resultModal.id_etablissement) {
        //   //     this.entreprises.push(resultModal);
        //   //     return;
        //   //   }
        //   // }
        // });
        break;
      case "facturation":
        this.router.navigateByUrl("facturation");

        break;
      case "report":
        this.router.navigateByUrl("facturation");

        break;

      default:
        break;
    }
  }
  contact() {
    const dialogRef = this.dialog.open(CustomerService);
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
    });
  }
}
