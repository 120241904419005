import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { MdcDialog } from "@angular-mdc/web";
import { ProduitEditModalComponent } from "../../../shared/components/produit-edit-modal/produit-edit-modal.component";
import { DialogBox } from "../../../shared/helpers/dialogBox";
import { Loader } from "../../../shared/helpers/loader";
declare var jQuery: any;

@Component({
  selector: "app-articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.scss"],
})
export class ArticlesComponent implements OnInit {
  articles = [];
  term: String = "";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  actualMenuDep = null;
  actualMenuProd = null;
  actifMenu = false;
  constructor(private service: CorePrototype, private dialog: MdcDialog) {}

  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_products_in_squares).then((data: any) => {
      // this.term.replace(' ','_')
      console.log(data);
      data.body.forEach((square) => {
        square.produits.forEach((element) => {
          element.show = 1;
          element.animation = false;
          element.tmp_nom = element.nom_produit.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
          );
          element.sp_nom =
            element.tmp_nom.replace(" ", "_") + "_" + element.id_produit + "_1";
          element.sp_nom = element.sp_nom.split(" ").join("");
        });
      });
      this.articles = data.body;
      console.log(this.articles);
      Loader.stopLoading();
    });
  }

  edit(article) {
    article.mode = "1";
    var dialogRef = this.dialog.open(ProduitEditModalComponent, {
      data: article,
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      resultModal.show = 1;
      if (resultModal === "close" || resultModal === null) {
        if (resultModal.categorie_services) {
          var index = this.articles.findIndex(
            (item) =>
              item.id_departement ==
              resultModal.categorie_services[0].id_departement
          );

          var index1 = this.articles[index].data.services.findIndex(
            (item) => item.id_service == article.id_service
          );
          this.articles[index].data.services[index1] = resultModal;
        } else {
          var index = this.articles.findIndex(
            (item) =>
              item.id_departement ==
              resultModal.categorie_produits[0].id_departement
          );

          var index1 = this.articles[index].data.produits.findIndex(
            (item) => item.id_produit == article.id_produit
          );
          this.articles[index].data.produits[index] = resultModal;
        }
        return;
      }
    });
  }
  deleteArticle(article) {
    if (article.id_service) {
      DialogBox.confirmationMessage(this.dialog, {
        title: "Confirmation",
        body: "Etes vous sur de vouloir supprimer ce service",
      }).then((result: any) => {
        if (result) {
          this.service
            .post(ENDPOINTS.post_update_service, {
              id_service: article.id_service,
              is_deleted_service: "1",
            })
            .then((data: any) => {
              console.log(data);
              var index = this.articles.findIndex(
                (item) =>
                  item.id_departement ==
                  article.categorie_services[0].id_departement
              );

              var index1 = this.articles[index].data.services.findIndex(
                (item) => item.id_service == article.id_service
              );
              this.articles[index].data.services.splice(index1, 1);
            });
        } else {
        }
      });
    } else {
      DialogBox.confirmationMessage(this.dialog, {
        title: "Confirmation",
        body: "Etes vous sur de vouloir supprimer ce produit",
      }).then((result: any) => {
        if (result) {
          this.service
            .post(ENDPOINTS.post_update_product, {
              id_produit: article.id_produit,
              is_deleted_produit: "1",
            })
            .then((data: any) => {
              console.log(data);
              var index = this.articles.findIndex(
                (item) => item.id_caree == article.id_caree
              );

              var index1 = this.articles[index].produits.findIndex(
                (item) => item.id_produit == article.id_produit
              );
              this.articles[index].produits.splice(index1, 1);
            });
        } else {
        }
      });
    }
  }
  newProduct() {
    var dialogRef = this.dialog.open(ProduitEditModalComponent, {
      data: { mode: "0" },
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      resultModal.show = 1;
      if (resultModal !== "close" || resultModal !== null) {
        if (resultModal.id_service) {
          var index = this.articles.findIndex(
            (item) =>
              item.id_departement ==
              resultModal.categorie_services[0].id_departement
          );
          this.articles[index].data.services.push(resultModal);
        } else {
          var index = this.articles.findIndex(
            (item) =>
              item.id_departement ==
              resultModal.categorie_produits[0].id_departement
          );
          this.articles[index].data.produits.push(resultModal);
        }
        // alert('Closed');categories_produit
        // this.entreprises.push(resultModal);
        return;
      }
    });
  }
  newProductStock(produit, i, dep, j) {
    // var dialogRef = this.dialog.open(StockModalComponent, {
    //   data: { id_produit: produit.id_produit, mode: "0" },
    // });
    // console.log(this.articles[j]);
    // dialogRef.afterClosed().subscribe((resultModal: any) => {
    //   // console.log(resultModal);
    //   if (resultModal !== "close" || resultModal !== null) {
    //     // alert('Closed');
    //     // this.entreprises.push(resultModal);
    //     if (!resultModal.quantite_stock || resultModal.quantite_stock === NaN) {
    //     } else {
    //       this.articles[j].produits[i].quantite_en_stock =
    //         parseInt(this.articles[j].produits[i].quantite_en_stock) +
    //         parseInt(resultModal.quantite_stock);
    //     }
    //     return;
    //   }
    // });
  }
  logTabArticle(event) {}
  showTabArticle(type) {
    jQuery(".tab-body").hide();
    jQuery("#" + type).show();
  }
  searcher() {
    var term = this.term.toUpperCase();
    for (let index = 0; index < this.articles.length; index++) {
      this.articles[index].produits.forEach((element) => {
        if (element.nom_produit.toUpperCase().indexOf(term) > -1) {
          // console.log(element.matricule_commande.toUpperCase().indexOf(term));
          element.show = 1;
        } else {
          element.show = 0;
        }
      });
      this.articles[index].services.forEach((element) => {
        if (element.nom_service.toUpperCase().indexOf(term) > -1) {
          // console.log(element.matricule_commande.toUpperCase().indexOf(term));
          element.show = 1;
        } else {
          element.show = 0;
        }
      });
    }
  }

  animateMenu(i, j) {
    // product.animation = true;
    console.log(i, j, this.actualMenuDep, this.actualMenuProd);

    this.actifMenu = true;
    if (this.actualMenuDep !== null) {
      this.articles[j].produits[this.actualMenuProd].animation = false;

      this.actualMenuDep = j;
      this.actualMenuProd = i;

      this.articles[j].produits[i].animation = true;
      console.log(this.articles[j].produits[i]);
    } else {
      this.actualMenuDep = j;
      this.actualMenuProd = i;

      this.articles[j].produits[i].animation = true;
    }
  }
  animationMenuOff(i, j) {
    this.articles[j].produits[i].animation = false;
  }

  animateMenuService(i, j) {
    // product.animation = true;
    console.log(i, j, this.actualMenuDep, this.actualMenuProd);

    this.actifMenu = true;
    if (this.actualMenuDep !== null) {
      this.articles[j].services[this.actualMenuProd].animation = false;

      this.actualMenuDep = j;
      this.actualMenuProd = i;

      this.articles[j].services[i].animation = true;
      console.log(this.articles[j].services[i]);
    } else {
      this.actualMenuDep = j;
      this.actualMenuProd = i;

      this.articles[j].services[i].animation = true;
    }
  }
  openListView() {
    jQuery("#icon").hide();
    jQuery("#list").show();
  }
  openIconView() {
    jQuery("#list").hide();
    jQuery("#icon").show();
  }
  animationMenuServiceOff(i, j) {
    this.articles[j].services[i].animation = false;
  }
}
