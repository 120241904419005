import { Component, OnInit } from '@angular/core';
import { MdcDialog, MdcDialogRef } from '@angular-mdc/web';
import { CorePrototype } from '../../services/core.prototype';
import { ENDPOINTS } from '../../../shared/model/endpoints';
import { VersionModalComponent } from '../../../shared/components/version-modal/version-modal.component';
import { Loader } from '../../../shared/helpers/loader';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {
  versions = [];
  constructor(
    public dialog: MdcDialog,
    public service: CorePrototype
  ) { }
  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_versions).then((data: any) => {
      Loader.stopLoading();
      data.body.forEach(element => {
        element.type_text = APP_TYPES[element.type];
      });
      this.versions = data.body;
    });
  }
  newVersion() {
    var dialogRef: MdcDialogRef<any> = null;
    dialogRef = this.dialog.open(VersionModalComponent);
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      if (resultModal !== '' && resultModal !== null && resultModal !== undefined && resultModal !== 'close') {
        console.log(resultModal);
        this.versions.push(resultModal);
      }

    });
  }
  updateVersion(version, i) {
    var dialogRef: MdcDialogRef<any> = null;

    dialogRef = this.dialog.open(VersionModalComponent, {
      data: version,
    });
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      if (resultModal !== '' && resultModal !== null && resultModal !== undefined&& resultModal !== 'close') {

        console.log(resultModal);
        this.versions[i] = resultModal;
      }
    });
  }
}
export const APP_TYPES = {
  '1': "Accounting",
  '2': "Marketplace",
  '3': "Marchand light"
}
