import { Component, OnInit } from "@angular/core";
import { MethodUtils } from "../../../shared/helpers/methodUtils";

import { CorePrototype } from "../../../core/services/core.prototype";
import { LocalStorage } from "../../helpers/localSortage";
import { Router } from "@angular/router";
import { DialogBox } from "../../helpers/dialogBox";
import { BASE_URL } from "../../model/codes";
import { MdcDialog } from "@angular-mdc/web";
import { ENDPOINTS } from "../../model/endpoints";
import { NgForm } from "@angular/forms";
import { User } from "../../helpers/user";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
declare var jQuery: any;
@Component({
  selector: "connectUser-component",
  templateUrl: "./connect_user.component.html",
  styleUrls: ["./connect_user.component.scss"],
  animations: [
    trigger("formLoginSlide", [
      state("show", style({ display: "flex", transform: "translateX(0%)" })),
      state("hide", style({ display: "none", transform: "translateX(-100%)" })),
      transition("show => hide", animate("600ms ease-out")),
      transition("hide => show", animate("1000ms ease-in")),
    ]),
    trigger("formAccountSlide", [
      state("show", style({ display: "none", transform: "translateX(-100%)" })),
      state("hide", style({ display: "flex", transform: "translateX(0%)" })),
      transition("show => hide", animate("600ms ease-out")),
      transition("hide => show", animate("1000ms ease-in")),
    ]),
  ],
})
export class ConnectUserComponent implements OnInit {
  methodUtils = new MethodUtils();
  constructor(
    private service: CorePrototype,
    private router: Router,
    public dialog: MdcDialog
  ) {}
  /**
   * @var Boolean login error indicator
   */
  noLoginError = true;

  /**
   * @var User user class utility instance
   */
  showLogin = true;

  user = {
    email: "",
    password: "",
    nom: "",
    prenom: "",
    type_account: 2,
  };
  ngOnInit() {}

  public login(form: NgForm) {
    console.log(form);
    if (form.form.status === "VALID") {
      // jQuery("#loader").show();

      if (
        form.form.value.email == "plusultra@gmail.com" &&
        form.form.value.password == "getmehere"
      ) {
        console.log(form.form.value);
        LocalStorage.setItem("Squares_user", JSON.stringify(form.form.value));
        // this.router.navigate["/home"];
        // window.location.href = "http://localhost:4200/home";
        window.location.href = "https://www.adminsquares.seeds.cm/home";
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Not happening bro",
        });
        // jQuery("#loader").hide();
        return;
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Remplissez tous les champs s'il vous plait",
      });
      return;
    }
  }

  public createAccount(form: NgForm) {
    console.log(form);
    if (form.form.status === "VALID") {
      jQuery("#loaderCreate").show();
      this.service
        .post(ENDPOINTS.post_user, form.form.value)
        .then((data: any) => {
          console.log(data);
          jQuery("#loaderCreate").hide();
          // debugger;
          // Loader.stopLoading();
          if (data === undefined) {
            DialogBox.showMessage(this.dialog, {
              title: "Erreur",
              body: "Problème avec le serveur",
            });
            return;
          }
          if (data.body.actif === "0") {
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body:
                "Veuillez contacter l'administration pour l'activation de votre compte",
            });
            return;
          }
          if (data.code === "102") {
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Cette adresse email est deja utilise",
            });
            return;
          }
          if (data.code === "101") {
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Veuillez inserer un mot de passe",
            });
            return;
          }
          if (data.message == "Account Not found") {
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Ce compte n'existe pas",
            });
            return;
          }
          this.user.email = "";
          this.user.nom = "";
          this.user.password = "";
          this.user.prenom = "";
          LocalStorage.setItem("Squares_user", JSON.stringify(data.body));
          // window.location.href = "https://www.adminteslim.seeds.cm/home";
          window.location.href = "http://www.accountsquares.seeds.cm/ets";
        });
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Remplissez tous les champs s'il vous plait",
      });
      return;
    }
  }
  toogleShow() {
    this.showLogin = !this.showLogin;
  }
  get stateName() {
    return this.showLogin ? "show" : "hide";
  }
}
