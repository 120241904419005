import { Component, Input } from "@angular/core";
// import { mdiAbTesting } from "../../model/mdi";
import * as Mdi from "@mdi/js";

@Component({
  selector: "icon",
  template: `
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      style="display:inline-block;width:1.2rem"
    >
      <path
        [attr.d]="icon"
        [attr.fill]="color"
        d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z"
        fill="#000"
      />
    </svg>
  `,
})
export class IconComponent {
  moduleSpecifier = "../../model/mdi";
  @Input("path") data: string;
  icon = "M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z";
  @Input("color") color: string = "#000";
  constructor() {
    // console.log(this.path);
    // console.log(this.data);
    // console.log(Mdi[]);
    // import("../../model/mdi").then((someModule) => {
    //   // this.icon = someModule[this.data];
    //   console.log(this.data);
    // });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.icon = Mdi[this.data];
    // console.log(this.data);
  }
}
