/**
 * @classdesc Firebase notification handeler.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name DateTableService
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { CorePrototype } from '../../core/services/core.prototype';
import { ENDPOINTS } from '../model/endpoints';
import { STATE, STATE_PACKET, LOGO_URL } from '../model/codes';
import { Router } from '@angular/router';

@Injectable()
export class MessagingService {

  private $messageSource = new BehaviorSubject(null);
  currentMessage = this.$messageSource.asObservable();

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    private service: CorePrototype) {
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        // this.updateToken(userId, token);
        this.service.post(ENDPOINTS.post_update_admin_token, { firebase_token: token, slug: navigator.productSub + '_' + new Date().getTime().toString(), timestamp: new Date().getTime() }).then((data: any) => {
          console.log(data);

          // LocalStorage.setItem("Teslim_user", JSON.stringify(data.body));
        })
        return token;
        // this.
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        return null;
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    // this.angularFireMessaging.messaging.subscribe((_messaging) => {
    //   console.log(_messaging);
    // })
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log("new message received. ", payload);
        var message = 'Nouvelle commande squares.cm';
        var title = 'Nouvelle commande squares.cm';
        var icon = LOGO_URL;
        payload.data.body = JSON.parse(payload.data.body);
        message = 'Commande de ' + payload.data.body.prix_total_commande;

        const option = {
          body: message,
          icon: icon,
          vibrate: [300, 100, 400],
          tag: payload.data.title
        }

        var n = new Notification(title, option);
        let that = this;
        n.onclick = function (event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          // window.open('https://www.adminsquares.seeds.cm', '_blank');
          n.close(); // Android needs explicit close.
          console.log(event);
          that.router.navigate(['/commandes']);
        }
        this.$messageSource.next(payload);
      })

  }
}
