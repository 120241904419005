import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MdcDialogRef,
  MDC_DIALOG_DATA,
  MdcDialog,
  MdcSelectChange,
} from "@angular-mdc/web";

import { Router } from "@angular/router";
import { STATE } from "../../model/codes";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { DialogBox } from "../../helpers/dialogBox";
import { NgForm } from "@angular/forms";
@Component({
  selector: "app-order-details-modal",
  templateUrl: "./order-details-modal.html",
  styleUrls: ["./order-details-modal.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsModal {
  /**
   * The log user keeper
   */
  message: any = {};
  commande: any = {};
  client: any = {
    id_client: "0",
    nom_client: "",
    prenom_client: "",
    email_client: "",
    numero_client: "",
  };
  option_livraison: any = {
    id_option_livraison: "0",
    nom_option_livraison: "",
    prix_option_livraison: 0,
  };
  owner: any = {
    nom: "",
    email: "",
    localisation: "",
  };
  sum_prix_prods = 0;
  showModif = true;
  etat = "";
  addresse = {
    nom_adresse_livraison: "",
    prenom_adresse_livraison: "",
    telephone_adresse_livraison: "",
    details_adresse_livraison: "",
    id_client: "",
    region_adresse_livraison: "",
    departement_adresse_livraison: "",
    selected_adresse: false,
  };
  serveuse = { nom_employe: "", id_employe: "" };
  sum_prix_prods_tax = 0;
  constructor(
    private service: CorePrototype,
    private dialog: MdcDialog,
    private route: Router,
    public dialogRef: MdcDialogRef<OrderDetailsModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    data.produits_commanders.forEach((element) => {
      element.etat_commande_produit = STATE[element.etat_commande_produit];
      this.sum_prix_prods =
        this.sum_prix_prods + parseFloat(element.prix_produit);
      element.tmp_nom = element.nom_produit.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      element.sp_nom =
        element.tmp_nom.replace(" ", "_") + "_" + element.id_produit + "_1";
      element.sp_nom = element.sp_nom.split(" ").join("");
    });
    this.sum_prix_prods_tax = Math.ceil(0.2 * this.sum_prix_prods);
    data.services_commanders.forEach((element) => {
      element.etat_commande_service = STATE[element.etat_commande_service];
    });
    this.commande = data;
    switch (this.commande.moyen_paiement) {
      case "1":
        this.commande.moyen_paiement = "MTN Mobile Money";
        break;
      case "2":
        this.commande.moyen_paiement = "Orange Money";
        break;
      case "3":
        this.commande.moyen_paiement = "Paiement a la livraison";
        break;
      case "4":
        this.commande.moyen_paiement = "Cash";
        break;
      default:
        this.commande.moyen_paiement = "Paiement a la livraison";
        break;
    }
    // if (this.commande.id_option_livraison != "0") {
    //   this.service
    //     .get(ENDPOINTS.get_option_livraison, {
    //       id_option_livraison: this.commande.id_option_livraison,
    //     })
    //     .then((data: any) => {
    //       this.option_livraison = data.body;
    //     });
    // }
    // if (this.commande.id_adresse_livraison != "0") {
    //   this.service
    //     .get(ENDPOINTS.get_adresse_livraison, {
    //       id_adresse_livraison: this.commande.id_adresse_livraison,
    //     })
    //     .then((data: any) => {
    //       this.addresse = data.body;
    //     });
    // }
    // if (this.commande.id_employe != "0") {
    //   this.service
    //     .get(ENDPOINTS.get_employee, { id_employe: this.commande.id_employe })
    //     .then((data: any) => {
    //       this.serveuse = data.body;
    //     });
    // }

    console.log(data);
  }

  ngOnInit() {
    // this.service.get(ENDPOINTS.get_user, { id_user: this.commande.id_user }).then((user: any) => {
    //   this.owner = user.body;
    // })
    if (
      this.commande.etat_commande == "3" ||
      this.commande.etat_commande == "5"
    ) {
      if (this.commande.id_type_produit) {
        this.showModif = true;
      } else {
        this.showModif = false;
      }
    }
  }
  updatePaid(event: MdcSelectChange) {
    // console.log(type);

    if (event.source.ngControl.control.touched == false) {
    } else {
      console.log(event);
      Loader.load();
      this.service
        .post(ENDPOINTS.post_update_customer_order_lite, {
          firebase_user_token: "",
          etat_commande: this.commande.etat_commande_int,
          id_commande: this.commande.id_commande,
          is_paid_commande: event.value,
          adminUpdate: true
        })
        .then((user: any) => {
          Loader.stopLoading();
          console.log(user);
          // this.dialogRef.close(user.body);
        });
    }
  }
  updateDelivery(event: MdcSelectChange) {
    if (event.source.ngControl.control.touched == false) {
    } else {
      console.log(event);
      Loader.load();
      this.service
        .post(ENDPOINTS.post_update_customer_order_lite, {
          commande: JSON.stringify({
            firebase_user_token: "",
            etat_commande: event.value,
            id_commande: this.commande.id_commande,
            is_paid_commande: this.commande.is_paid_commande,
            adminUpdate: true
          })
        })
        .then((user: any) => {
          Loader.stopLoading();
          console.log(user);
          // this.dialogRef.close(user.body);
        });
    }
  }
  submit(params: NgForm) { }
  updateCommande(value) {
    this.commande.etat_commande = value;
    // this.commande.firebase_user_token = '';
    console.log(this.commande.type_categorie);
    console.log(value);
    if (this.commande.type_categorie === "1" && value === "2") {
      this.commande.etat_commande = "6";
    }
    if (value === "5" && this.commande.etat_commande === "3") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours",
      });
      return;
    }
    if (value === "5" && this.commande.etat_commande === "6") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours",
      });
      return;
    }
    Loader.load();
    // this.service.post(ENDPOINTS.post_update_order, { firebase_user_token: '', etat_commande: this.commande.etat_commande, id_commande: this.commande.id_commande,commentaire_commande:this.commande.commentaire_commande }).then((user: any) => {
    //   Loader.stopLoading();
    //   console.log(user);
    //   this.dialogRef.close(user.body);
    // })
  }
}
