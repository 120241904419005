import { Component, OnInit, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialogRef, MdcDialog, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { ENDPOINTS } from "../../model/endpoints";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from "../../helpers/loader";
import { ImageUtils } from "../../helpers/imageUtils";
import { LocalStorage } from "../../helpers/localSortage";
import { element } from "protractor";

@Component({
  selector: "app-produit-edit-modal",
  templateUrl: "./produit-edit-modal.component.html",
  styleUrls: ["./produit-edit-modal.component.scss"],
})
export class ProduitEditModalComponent implements OnInit {
  produit = {
    id_produit: "",
    nom_produit: "",
    image_produit: null,
    prix_classic: 1,
    prix_elegance: 1,
    prix_prestige: 1,
    id_categorie: "-1",
    mode: "0",
    categories: [],
    categorie_produits: [],
    categorie_services: [],
    carees: [],
    description_produit: "",
    for_kitchen: "0",
    for_kitchen_bool: false,
    nombre_minimum_commande: 1,
  };
  fileLogo = null;
  type_user = "1";
  type_produits = [
    { id: "1", titre: "Boissons" },
    { id: "2", titre: "Plats" },
  ];
  categories = [];
  carees = [];
  categories_produits = [];
  categories_services = [];
  categories_global = [];
  carees_global = [];
  categories_global_produits = [];
  categories_global_services = [];
  selected_categories_global = [];
  selected_carees_global = [];
  selected_categories_global_services = [];
  selected_categories_global_produits = [];
  removed_categories_global = [];
  removed_carees_global = [];
  removed_categories_global_services = [];
  removed_categories_global_produits = [];
  users = [];
  checked_boisson = true;
  checked_plat = false;
  min_val = "1";
  departments = [];
  produit_type = true;
  service_type = false;
  ets_actuel = JSON.parse(LocalStorage.getItem("currentEts"));

  constructor(
    public sanitizer: DomSanitizer,
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<ProduitEditModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null) {
      if (data.mode === "1") {
        this.produit = data;
        console.log(data);
        if (data.categorie_produits) {
          this.produit.prix_classic = data.prix_produit_classic;
          this.produit.prix_elegance = data.prix_produit_elegance;
          this.produit.prix_prestige = data.prix_produit_prestige;
          this.produit_type = true;
          this.produit.id_produit = data.id_produit;
          this.produit.description_produit = data.description_produit;
          this.produit.categorie_produits.forEach((element) => {
            element.old = true;
          });
          this.produit.carees.forEach((element) => {
            element.old = true;
          });
          console.log(this.produit.categorie_produits);
          this.selected_categories_global_produits = this.produit.categorie_produits.slice();
          this.selected_carees_global = this.produit.carees.slice();
        } else {
          this.service_type = true;
          this.produit.prix_classic = data.prix_service_classic;
          this.produit.prix_elegance = data.prix_service_elegance;
          this.produit.prix_prestige = data.prix_service_prestige;
          this.produit.nom_produit = data.nom_service;
          this.produit.image_produit = data.image_service;
          this.produit.id_produit = data.id_service;
          this.produit.nombre_minimum_commande = 1;
          this.produit.description_produit = data.description_service;
          this.produit.categorie_services.forEach((element) => {
            element.old = true;
          });
          this.produit.carees.forEach((element) => {
            element.old = true;
          });
          console.log(this.produit.categorie_services);
          this.selected_categories_global_services = this.produit.categorie_services.slice();
          this.selected_carees_global = this.produit.carees.slice();
        }
      } else {
        this.produit.mode = data.mode;
      }
      if (this.produit.for_kitchen == "0") {
        this.produit.for_kitchen_bool = false;
      } else {
        this.produit.for_kitchen_bool = true;
      }
    }
  }
  ngOnInit() {
    this.service
      .get(ENDPOINTS.get_categories_products_by_institute, {
        id_etablissement: this.ets_actuel.id_etablissement,
        type_categorie: 3,
      })
      .then((data: any) => {
        console.log(data);
        this.categories_global_produits = data.body.produits;
        this.categories_global_services = data.body.services;

        this.categories_produits = data.body.produits;
        this.categories_services = data.body.services;
      });
    this.service.get(ENDPOINTS.get_squares).then((data: any) => {
      console.log(data);
      this.carees = data.body;
      this.carees_global = data.body;
    });
    // this.service.get(ENDPOINTS.get_departments, { id_etablissement: this.ets_actuel.id_etablissement }).then((data: any) => {
    //   console.log(data);
    //   this.departments = data.body;

    // });
    // this.service.get(ENDPOINTS.get_users, { type: '2' }).then((data: any) => {
    //   this.users = data.body;

    // })
  }
  // onchangeLogo(event) {
  //   this.fileLogo = event.target.files[0];
  //   this.produit.image_produit = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.fileLogo));
  // }
  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.produit.image_produit = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
        // this.produit.image_service = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.fileLogo));
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  radioChange(event) {
    console.log(event);
    this.type_user = event.value;
    // if(event.target.value === )
  }

  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }
    var tmp_prod = Object.assign(this.produit, {});
    // delete tmp_prod.
    // console.log(this.produit);
    console.log(this.selected_carees_global);
    console.log(this.removed_carees_global);

    if (this.type_user === "1") {
      console.log(tmp_prod);
      if (this.selected_categories_global_produits.length === 0) {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Choissisez au moins 1 categorie",
        });
        return;
      } else {
        tmp_prod["categories[]"] = [];
        tmp_prod["carees[]"] = [];
        if (tmp_prod.mode === "0") {
          this.selected_categories_global_produits.forEach((element) => {
            tmp_prod["categories[]"].push(element.id_categorie_produit);
          });
          this.selected_carees_global.forEach((element) => {
            tmp_prod["carees[]"].push(element.id_caree);
          });
        } else {
          var index = 0;
          // var that = this;
          this.selected_categories_global_produits.forEach((element) => {
            var same: any = false;
            // let that = this;
            // TO verifiy if the cat has not already been added
            this.produit.categorie_produits.forEach((elementProd) => {
              if (
                element.id_categorie_produit ===
                elementProd.id_categorie_produit
              ) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["categories[]"].push(
                element.id_categorie_produit + "@1"
              );
            }
            index++;
          });
          this.removed_categories_global_produits.forEach((element) => {
            var same: any = false;
            // let that = this;
            // to verify if the cat to be removed has not alredy been added
            this.selected_categories_global_produits.forEach((elementProd) => {
              if (
                element.id_categorie_produit ===
                elementProd.id_categorie_produit
              ) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["categories[]"].push(
                element.id_categorie_produit + "@2"
              );
            }
            index++;
          });
          this.selected_carees_global.forEach((element) => {
            console.log(element);
            var same: any = false;
            // let that = this;
            // TO verifiy if the cat has not already been added
            this.produit.carees.forEach((elementProd) => {
              if (element.id_caree === elementProd.id_caree) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["carees[]"].push(element.id_caree + "@1");
            }
            index++;
          });
          this.removed_carees_global.forEach((element) => {
            var same: any = false;
            // let that = this;
            // to verify if the cat to be removed has not alredy been added
            this.selected_carees_global.forEach((elementProd) => {
              if (element.id_caree === elementProd.id_caree) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["carees[]"].push(element.id_caree + "@2");
            }
            index++;
          });
        }
      }
    } else {
      console.log(tmp_prod);
      if (this.selected_categories_global_services.length === 0) {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Choissisez au moins 1 categorie",
        });
        return;
      } else {
        tmp_prod["categories[]"] = [];
        tmp_prod["carees[]"] = [];
        if (tmp_prod.mode === "0") {
          this.selected_categories_global_services.forEach((element) => {
            tmp_prod["categories[]"].push(element.id_categorie_service);
          });
          this.selected_carees_global.forEach((element) => {
            tmp_prod["carees[]"].push(element.id_caree);
          });
        } else {
          var index = 0;
          // var that = this;
          this.selected_categories_global_services.forEach((element) => {
            var same: any = false;
            // let that = this;
            // TO verifiy if the cat has not already been added
            this.produit.categorie_services.forEach((elementProd) => {
              if (
                element.id_categorie_service ===
                elementProd.id_categorie_service
              ) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["categories[]"].push(
                element.id_categorie_service + "@1"
              );
            }
            index++;
          });
          this.removed_categories_global_services.forEach((element) => {
            var same: any = false;
            // let that = this;
            // to verify if the cat to be removed has not alredy been added
            this.selected_categories_global_services.forEach((elementProd) => {
              if (
                element.id_categorie_service ===
                elementProd.id_categorie_service
              ) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["categories[]"].push(
                element.id_categorie_service + "@2"
              );
            }
            index++;
          });
          this.selected_carees_global.forEach((element) => {
            var same: any = false;
            // let that = this;
            // TO verifiy if the cat has not already been added
            this.produit.carees.forEach((elementProd) => {
              if (element.id_caree === elementProd.id_caree) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["carees[]"].push(element.id_caree + "@1");
            }
            index++;
          });
          this.removed_carees_global.forEach((element) => {
            var same: any = false;
            // let that = this;
            // to verify if the cat to be removed has not alredy been added
            this.selected_carees_global.forEach((elementProd) => {
              if (element.id_caree === elementProd.id_caree) {
                same = true;
              }
            });
            if (same == false) {
              tmp_prod["carees[]"].push(element.id_caree + "@2");
            }
            index++;
          });
        }
      }
    }

    // tmp_prod.categories = this.selected_categories_global;
    var final: any = {};
    if (this.produit.for_kitchen_bool == false) {
      tmp_prod.for_kitchen = "0";
    } else {
      tmp_prod.for_kitchen = "1";
    }
    console.log(tmp_prod);
    switch (tmp_prod.mode) {
      case "0":
        if (this.fileLogo !== null) {
          tmp_prod.image_produit = this.fileLogo;
        } else {
          // delete cat.couverture_profil;
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour créer un produit",
          });
          return;
        }
        delete tmp_prod.mode;
        delete tmp_prod.id_produit;
        // delete tmp_prod.id_type_produit;
        if (this.type_user === "1") {
          final = {
            image_produit: tmp_prod.image_produit,
            nom_produit: tmp_prod.nom_produit,
            nombre_minimum_commande: tmp_prod.nombre_minimum_commande,
            description_produit: tmp_prod.description_produit,
            prix_produit_classic: tmp_prod.prix_classic,
            prix_produit_elegance: tmp_prod.prix_elegance,
            prix_produit_prestige: tmp_prod.prix_prestige,
            for_kitchen: tmp_prod.for_kitchen,
            nom_etablissement: this.ets_actuel.nom_etablissement,
          };
        } else {
          final = {
            image_service: tmp_prod.image_produit,
            nom_service: tmp_prod.nom_produit,
            description_service: tmp_prod.description_produit,
            prix_service_classic: tmp_prod.prix_classic,
            prix_service_elegance: tmp_prod.prix_elegance,
            prix_service_prestige: tmp_prod.prix_prestige,
            for_kitchen: tmp_prod.for_kitchen,
            nom_etablissement: this.ets_actuel.nom_etablissement,
          };
        }
        final["categories[]"] = tmp_prod["categories[]"];
        final["carees[]"] = tmp_prod["carees[]"];
        final.id_etablissement = this.ets_actuel.id_etablissement;
        Loader.load();
        if (this.type_user === "1") {
          this.service.post(ENDPOINTS.post_product, final).then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        } else {
          this.service.post(ENDPOINTS.post_service, final).then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        }

        break;
      case "1":
        if (this.fileLogo !== null) {
          tmp_prod.image_produit = this.fileLogo;
        }
        delete tmp_prod.mode;
        delete tmp_prod.categories;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;

        if (this.type_user === "1") {
          final = {
            id_produit: tmp_prod.id_produit,
            image_produit: tmp_prod.image_produit,
            nom_produit: tmp_prod.nom_produit,
            nombre_minimum_commande: tmp_prod.nombre_minimum_commande,
            description_produit: tmp_prod.description_produit,
            prix_produit_classic: tmp_prod.prix_classic,
            prix_produit_elegance: tmp_prod.prix_elegance,
            prix_produit_prestige: tmp_prod.prix_prestige,
            for_kitchen: tmp_prod.for_kitchen,
            deep_link:
              this.ets_actuel.id_etablissement + "/" + tmp_prod.id_produit,
            nom_etablissement: this.ets_actuel.nom_etablissement,
          };
        } else {
          final = {
            id_service: tmp_prod.id_produit,
            image_service: tmp_prod.image_produit,
            nom_service: tmp_prod.nom_produit,
            description_service: tmp_prod.description_produit,
            prix_service_classic: tmp_prod.prix_classic,
            prix_service_elegance: tmp_prod.prix_elegance,
            prix_service_prestige: tmp_prod.prix_prestige,
            for_kitchen: tmp_prod.for_kitchen,
            deep_link:
              this.ets_actuel.id_etablissement + "/" + tmp_prod.id_produit,
            nom_etablissement: this.ets_actuel.nom_etablissement,
          };
        }
        final["categories[]"] = tmp_prod["categories[]"];
        final["carees[]"] = tmp_prod["carees[]"];
        final.id_etablissement = this.ets_actuel.id_etablissement;

        Loader.load();
        if (this.type_user === "1") {
          this.service
            .post(ENDPOINTS.post_update_product, final)
            .then((data: any) => {
              console.log(data);
              Loader.stopLoading();
              this.dialogRef.close(data.body);
            });
        } else {
          this.service
            .post(ENDPOINTS.post_update_service, final)
            .then((data: any) => {
              console.log(data);
              Loader.stopLoading();
              this.dialogRef.close(data.body);
            });
        }

        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }

  addChip(chip, type) {
    console.log(chip);
    console.log(type);
    let off: Boolean = false;
    if (type === "1") {
      if (chip.index !== -1) {
        this.selected_categories_global_produits.forEach((value) => {
          if (value.id_categorie_service === chip.value) {
            off = true;
          }
        });
        if (off === true) {
          return;
        }

        this.categories_global_produits.forEach((element) => {
          if (element.id_categorie_produit === chip.value) {
            this.selected_categories_global_produits.push(element);
          }
        });
      }
    } else {
      if (chip.index !== -1) {
        this.selected_categories_global_services.forEach((value) => {
          if (value.id_categorie_service === chip.value) {
            off = true;
          }
        });
        if (off === true) {
          return;
        }

        this.categories_global_services.forEach((element) => {
          if (element.id_categorie_service === chip.value) {
            this.selected_categories_global_services.push(element);
          }
        });
      }
    }

    console.log(this.selected_categories_global_produits);
    console.log(this.selected_categories_global_services);
  }
  removeChip(i, type) {
    console.log(i);
    if (type === "1") {
      var deleted = this.selected_categories_global_produits[i];
      let off: Boolean = false;

      if (this.produit.mode === "1") {
        this.removed_categories_global_produits.forEach((value) => {
          if (value.id_categorie_produit === deleted.id_categorie_produit) {
            off = true;
          }
        });
        if (off === true) {
          this.selected_categories_global_produits.splice(i, 1);
          return;
        }
        this.selected_categories_global_produits.forEach((element) => {
          if (element.id_categorie_produit === deleted.id_categorie_produit) {
            if (element.old === true) {
              this.removed_categories_global_produits.push(element);
            }
          }
        });
      }

      this.selected_categories_global_produits.splice(i, 1);
    } else {
      var deleted = this.selected_categories_global_services[i];
      let off: Boolean = false;

      if (this.produit.mode === "1") {
        this.removed_categories_global_services.forEach((value) => {
          if (value.id_categorie_service === deleted.id_categorie_service) {
            off = true;
          }
        });
        if (off === true) {
          this.selected_categories_global_services.splice(i, 1);
          return;
        }
        this.selected_categories_global_services.forEach((element) => {
          if (element.id_categorie_service === deleted.id_categorie_service) {
            if (element.old === true) {
              this.removed_categories_global_services.push(element);
            }
          }
        });
      }

      this.selected_categories_global_services.splice(i, 1);
    }
  }

  addChipSquare(chip) {
    let off: Boolean = false;
    if (this.selected_carees_global.length <= 1) {
      if (chip.index !== -1) {
        this.selected_carees_global.forEach((value) => {
          if (value.id_caree === chip.value) {
            off = true;
          }
        });
        if (off === true) {
          return;
        }

        this.carees_global.forEach((element) => {
          if (element.id_caree === chip.value) {
            this.selected_carees_global.push(element);
          }
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Vous pouvez ajouter seulement 2 carres par produit",
      });
    }
  }
  removeChipSquare(i) {
    var deleted = this.selected_carees_global[i];
    let off: Boolean = false;

    if (this.produit.mode === "1") {
      this.removed_carees_global.forEach((value) => {
        if (value.id_caree === deleted.id_caree) {
          off = true;
        }
      });
      if (off === true) {
        this.selected_carees_global.splice(i, 1);
        return;
      }
      this.selected_carees_global.forEach((element) => {
        if (element.id_caree === deleted.id_caree) {
          if (element.old === true) {
            this.removed_carees_global.push(element);
          }
        }
      });
    }

    this.selected_carees_global.splice(i, 1);
  }
}
