import { Component, OnInit } from '@angular/core';
import { CorePrototype } from '../../services/core.prototype';
import { MdcDialog, MdcDialogRef } from '@angular-mdc/web';
import { ENDPOINTS } from '../../../shared/model/endpoints';
import { Loader } from '../../../shared/helpers/loader';
import { PromoModalComponent } from '../../../shared/components/promo-modal/promo-modal.component';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  promotions = [];
  constructor(
    public dialog: MdcDialog,
    public service: CorePrototype
  ) { }
  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_promotions).then((data: any) => {
      Loader.stopLoading();
      this.promotions = data.body;
    });
  }
  newVersion() {
    var dialogRef: MdcDialogRef<any> = null;
    dialogRef = this.dialog.open(PromoModalComponent);
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      if (resultModal !== '' && resultModal !== null && resultModal !== undefined && resultModal !== 'close') {
        console.log(resultModal);
        this.promotions.push(resultModal);
      }

    });
  }
  updatePromo(version, i) {
    var dialogRef: MdcDialogRef<any> = null;

    dialogRef = this.dialog.open(PromoModalComponent, {
      data: version,
    });
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      if (resultModal !== '' && resultModal !== null && resultModal !== undefined&& resultModal !== 'close') {

        console.log(resultModal);
        this.promotions[i] = resultModal;
      }
    });
  }
}
export const APP_TYPES = {
  '1': "Accounting",
  '2': "Marketplace",
  '3': "Marchand light"
}
