// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCvGvEza-xZ2O5eE23obY-AxZ5Dvpn3UB0",
    authDomain: "squaresmarchand.firebaseapp.com",
    databaseURL: "https://squaresmarchand.firebaseio.com",
    projectId: "squaresmarchand",
    storageBucket: "squaresmarchand.appspot.com",
    messagingSenderId: "482428458606",
    appId: "1:482428458606:web:f6567146f0eb1bc6ad787f",
    measurementId: "G-CXH815CCE2",
    vapidKey: "BChztrPpY5BQ1VyTTlPqyYXJowkQwW1yb9N4Wd_AKHX273k6GnIb6-YUZQPS50Q7lFefqFPCnRxNLUWCurqh5Tg"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
