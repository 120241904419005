import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { User } from "../../../shared/helpers/user";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { Router, ActivatedRoute } from "@angular/router";
import { MdcDialog, MdcSliderChange } from "@angular-mdc/web";
import { DialogBox } from "../../../shared/helpers/dialogBox";
import * as BalloonEditor from "@ckeditor/ckeditor5-build-balloon";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NgForm } from "@angular/forms";
import { ImageUtils } from "../../../shared/helpers/imageUtils";
import { DomSanitizer } from "@angular/platform-browser";
import { Loader } from "../../../shared/helpers/loader";

import { OptionLivraisonModalComponent } from "src/app/shared/components/option-livraison-modal/option-livraison-modal.component";
import { SelectCategoryMarketplaceModal } from "../../../shared/components/select-category-marketplace-modal/select-category-marketplace-modal";

@Component({
  selector: "app-details-update",
  templateUrl: "./details-update.component.html",
  styleUrls: ["./details-update.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsUpdateComponent implements OnInit {
  options_livraison = [];
  public Editor = ClassicEditor;
  public model = {
    editorData: "<p>Hello, world!</p>",
  };
  article: any = {
    id_produit: "0",
    nom_produit: "",
    description_produit: "",
    image_produit: "",
    miniature_image_produit: "",
    sp_couverture_produit: "",
    prix_produit_classic: "",
    prix_produit_elegance: "",
    prix_produit_prestige: "",
    condition_produit: 5,
    date_creation_produit: "",
    date_update_produit: null,
    is_deleted_produit: "",
    nombre_demande_produit: "",
    nombre_minimum_commande: 1,
    code_produit: null,
    matricule_produit: "",
    couverture_produit_1: "",
    couverture_produit_2: "",
    couverture_produit_3: "",
    couverture_produit_4: "",
    quantite_en_stock: 0,
    seuil_stock: "5",
    for_kitchen: "0",
    deep_link: "",
    nom_etablissement: "",
    details_description: "",
    can_be_reserved: "1",
    can_be_delivered: "1",
    is_local_product: "1",
    marque: "",
    id_marque: 0,
    contenu_article: "",
    id_caree: "",
    nom_caree: "",
    id_sous_caree: "",
    nom_sous_caree: "",
    id_categorie_caree: "",
    nom_categorie_caree: "",
    can_visible_on_marketplace: "1",
    can_visible_on_marketplace_bool: true,
  };
  public config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "link",
        "bulletedList",
        "numberedList",
        "alignment",
        "fontColor",
        "fontBackgroundColor",
        "fontSize",
        "highlight",
        "code",
        "|",
        "indent",
        "outdent",
        "|",
        "CKFinder",
        "imageUpload",
        "blockQuote",
        "insertTable",
        "codeBlock",
        "pageBreak",
        "mediaEmbed",
        "undo",
        "redo",
      ],
    },
    language: "fr",
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
      ],
    },
    licenseKey: "",
    simpleUpload: {
      uploadUrl: "https://www.learn.seeds.cm" + "/Formation/handle_image_new",
    },
    allowedContent: true,
  };
  selected_tree = null;
  selected_string = "Catégorie";
  marques = [];
  title_message = "";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    public service: CorePrototype,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // console.log(LocalStorage.getItem('currentEts'));

    console.log(this.ets);
    let token = this.route.snapshot.paramMap.get("id");
    if (token != null && token != undefined) {
      this.title_message = "Mise a jour detaille";
      Loader.loadBack();

      this.service
        .get(ENDPOINTS.get_product, { id_produit: token })
        .then((data: any) => {
          this.model.editorData = data.body.details_description;
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_produit == "1") {
            data.body.etat_produit_bool = true;
          } else {
            data.body.etat_produit_bool = false;
          }
          if (data.body.can_visible_on_marketplace == "1") {
            data.body.can_visible_on_marketplace_bool = true;
          } else {
            data.body.can_visible_on_marketplace_bool = false;
          }
          if (data.body.is_local_product == "1") {
            data.body.is_local_product_bool = true;
          } else {
            data.body.is_local_product_bool = false;
          }
          this.article = data.body;

          if (
            this.article.nom_categorie_caree !== "" &&
            this.article.nom_caree !== "" &&
            this.article.nom_sous_caree !== ""
          ) {
            // var arr = this.article.deep_link.split('/');
            // arr = arr.pop();
            // arr = arr.shift();
            this.selected_string =
              this.article.nom_caree +
              "/" +
              this.article.nom_sous_caree +
              "/" +
              this.article.nom_categorie_caree;
          }
          Loader.stoploadingBack();
        });
    } else {
      this.title_message = "Nouveau produit";
      if (this.article.is_local_product == "1") {
        this.article.is_local_product_bool = true;
      } else {
        this.article.is_local_product_bool = false;
      }
    }
    this.service.get(ENDPOINTS.get_marques).then((data: any) => {
      this.marques = data.body;
    });
  }
  onchangeLogo(event, label) {
    var file = event.target.files[0];

    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        // this.fileLogo = event.target.files[0];
        switch (label) {
          case "image_produit":
            this.article.image_produit = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.image_produit_file = file;
            break;
          case "sp_couverture_produit":
            this.article.sp_couverture_produit = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.sp_couverture_produit_file = file;
            break;
          case "couverture_produit_1":
            this.article.couverture_produit_1 = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.couverture_produit_1_file = file;
            break;
          case "couverture_produit_2":
            this.article.couverture_produit_2 = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.couverture_produit_2_file = file;
            break;
          case "couverture_produit_3":
            this.article.couverture_produit_3 = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.couverture_produit_3_file = file;
            break;
          case "couverture_produit_4":
            this.article.couverture_produit_4 = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
            this.article.couverture_produit_4_file = file;
            break;

          default:
            break;
        }
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  editorReady(event) {
    console.log(event);
  }
  editorError(event) {
    console.log(event);
  }
  onInputSlider(event: MdcSliderChange) {
    console.log(event);
    this.article.condition_produit = event.value;
  }
  onChangeSlider(event: MdcSliderChange) {
    console.log(event);
    this.article.condition_produit = event.value;
  }
  openCategorieMarketplace() {
    var dialogRef = this.dialog.open(SelectCategoryMarketplaceModal, {});

    dialogRef.afterClosed().subscribe((resultModal: miniModel) => {
      console.log(resultModal);
      if (resultModal.hasOwnProperty("categorie_square")) {
        this.article.id_caree = resultModal.square.id_caree;
        this.article.nom_caree = resultModal.square.nom_caree;
        this.article.id_sous_caree = resultModal.sub_square.id_caree;
        this.article.nom_sous_caree = resultModal.sub_square.nom_caree;
        this.article.id_categorie_caree =
          resultModal.categorie_square.id_categorie_caree;
        this.article.nom_categorie_caree =
          resultModal.categorie_square.nom_categorie_caree;

        this.selected_string =
          resultModal.square.nom_caree +
          "/" +
          resultModal.sub_square.nom_caree +
          "/" +
          resultModal.categorie_square.nom_categorie_caree;

        this.article.deep_link =
          this.ets.id_etablissement +
          "/" +
          resultModal.square.id_caree +
          "/" +
          resultModal.sub_square.id_caree +
          "/" +
          resultModal.categorie_square.id_categorie_caree;

        if (this.article.id_produit != "0") {
          this.article.deep_link =
            this.article.deep_link + "/" + this.article.id_produit;
        }
      }
    });
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }
    this.article.contenu_article = this.model.editorData;
    var tmp_prod = Object.assign(this.article, {});
    if (
      this.article.prix_produit_classic < 0 ||
      this.article.quantite_en_stock < 0 ||
      this.article.nombre_minimum_commande < 1
    ) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Les champs du prix et la quantité en stock ne peuvent pas être inférieur a 0, le champ nombre minimum par commande ne peut pas être inférieur a 1",
      });
      return;
    }
    if (this.selected_string === "Catégorie") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez ranger votre produit",
      });
      return;
    }
    // var marque = this.marques.find()

    var index = this.marques.findIndex(
      (item) => item.id_marque == tmp_prod.id_marque
    );
    if (index != -1) {
      tmp_prod.marque = this.marques[index].nom_marque;
    }

    if (this.article.hasOwnProperty("image_produit_file")) {
      tmp_prod.image_produit = this.article.image_produit_file;
    } else {
      if (this.article.id_produit == "0") {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Veuillez inserer une image du produit",
        });
        return;
      }
    }
    if (this.article.hasOwnProperty("sp_couverture_produit_file")) {
      tmp_prod.sp_couverture_produit = this.article.sp_couverture_produit_file;
    }
    if (this.article.hasOwnProperty("couverture_produit_1_file")) {
      tmp_prod.couverture_produit_1 = this.article.couverture_produit_1_file;
    }
    if (this.article.hasOwnProperty("couverture_produit_2_file")) {
      tmp_prod.couverture_produit_2 = this.article.couverture_produit_2_file;
    }
    if (this.article.hasOwnProperty("couverture_produit_3_file")) {
      tmp_prod.couverture_produit_3 = this.article.couverture_produit_3_file;
    }
    if (this.article.hasOwnProperty("couverture_produit_4_file")) {
      tmp_prod.couverture_produit_4 = this.article.couverture_produit_4_file;
    }
    var final: any = {
      id_marque: tmp_prod.id_marque,
      nom_produit: tmp_prod.nom_produit,
      marque: tmp_prod.marque,
      quantite_en_stock: tmp_prod.quantite_en_stock,
      description_produit: tmp_prod.description_produit,
      prix_produit_classic: tmp_prod.prix_produit_classic,
      details_description: tmp_prod.contenu_article,
      deep_link: tmp_prod.deep_link,
      condition_produit: tmp_prod.condition_produit,
      image_produit: tmp_prod.image_produit,
      sp_couverture_produit: tmp_prod.sp_couverture_produit,
      couverture_produit_1: tmp_prod.couverture_produit_1,
      couverture_produit_2: tmp_prod.couverture_produit_2,
      couverture_produit_3: tmp_prod.couverture_produit_3,
      couverture_produit_4: tmp_prod.couverture_produit_4,
      id_etablissement: this.ets.id_etablissement,
      nom_etablissement: this.ets.nom_etablissement,
      id_caree: tmp_prod.id_caree,
      nom_caree: tmp_prod.nom_caree,
      id_sous_caree: tmp_prod.id_sous_caree,
      nom_sous_caree: tmp_prod.nom_sous_caree,
      id_categorie_caree: tmp_prod.id_categorie_caree,
      nom_categorie_caree: tmp_prod.nom_categorie_caree,
    };

    if (this.article.can_be_reserved_bool == true) {
      final.can_be_reserved = 1;
    } else {
      final.can_be_reserved = 0;
    }
    if (this.article.can_be_delivered_bool == true) {
      final.can_be_delivered = 1;
    } else {
      final.can_be_delivered = 0;
    }
    if (this.article.for_kitchen == true) {
      final.for_kitchen = 1;
    } else {
      final.for_kitchen = 0;
    }
    if (this.article.etat_produit_bool == true) {
      final.etat_produit = 1;
    } else {
      final.etat_produit = 0;
    }
    if (this.article.can_visible_on_marketplace_bool == true) {
      final.can_visible_on_marketplace = 1;
    } else {
      final.can_visible_on_marketplace = 0;
    }
    if (this.article.is_local_product_bool == true) {
      final.is_local_product = 1;
    } else {
      final.is_local_product = 0;
    }
    if (
      final.couverture_produit_1 == "null" ||
      final.couverture_produit_1 == "NULL"
    ) {
      final.couverture_produit_1 = "";
    }
    if (
      final.couverture_produit_2 == "null" ||
      final.couverture_produit_2 == "NULL"
    ) {
      final.couverture_produit_2 = "";
    }
    if (
      final.couverture_produit_3 == "null" ||
      final.couverture_produit_3 == "NULL"
    ) {
      final.couverture_produit_3 = "";
    }
    if (
      final.couverture_produit_4 == "null" ||
      final.couverture_produit_4 == "NULL"
    ) {
      final.couverture_produit_4 = "";
    }

    tmp_prod["categories[]"] = [];
    final["categories[]"] = tmp_prod["categories[]"];
    final.source = "2";
    if (this.article.id_produit == "0") {
      tmp_prod["categories[]"].push(this.ets.id_categorie_produit + "@1");

      Loader.loadBack();
      this.service.post(ENDPOINTS.post_product, final).then((data: any) => {
        console.log(data);
        Loader.stoploadingBack();
        this.router.navigate(["/articles"]);
      });
    } else {
      final.id_produit = tmp_prod.id_produit;

      Loader.loadBack();
      this.service
        .post(ENDPOINTS.post_update_product, final)
        .then((data: any) => {
          console.log(data);
          Loader.stoploadingBack();
          this.router.navigate(["/articles"]);
        });
    }
  }
}

export class miniModel {
  square: any;
  sub_square: any;
  categorie_square: any;
}
