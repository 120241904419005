import { Component, OnInit } from '@angular/core';
import { CorePrototype } from '../../../core/services/core.prototype';
// import { CoreAccessPrototypeService } from "./../../../core/services/core-access.prototype.services"

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /**
   * @var Array partners the list of partners
   */
  partners: any = [];

  constructor(private service: CorePrototype) { }

  ngOnInit() {
    
  }
  /**
   * Navigate to a specific link
   * @param link The link to navigate to
   */
  goTo(link) {
    window.open(link,'_blank');
  }

}
