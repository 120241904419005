import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { MdcDialog } from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { STATE } from "../../../shared/model/codes";
import { Loader } from "../../../shared/helpers/loader";
import { OrderDetailsModal } from "../../../shared/components/order-details-modal/order-details-modal";
import { DateUtils } from '../../../shared/helpers/dateUtils';

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  orders = [];
  term = "";
  date_debut = "2019/12/12";
  date_fin = "2019/12/12";
  displayModal = false;
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(private service: CorePrototype, private dialog: MdcDialog) { }

  ngOnInit() {
    var week = DateUtils.actualDay(new Date(), {});
    this.date_debut = week[0].dayDate;
    // this.date_debut = '04-02-2020';
    this.date_fin = week[6].dayDate;
    this.updateData();
  }
  updateData() {
    var debut = new Date(this.date_debut.replace('-', '/'));
    debut.setHours(0);
    debut.setMinutes(0);
    debut.setSeconds(0);

    var fin = new Date(this.date_fin.replace('-', '/'))
    fin.setHours(23);
    fin.setMinutes(58);
    fin.setSeconds(60);
    Loader.load();
    this.service.get(ENDPOINTS.get_marketplace_orders, { date_debut: debut.getTime(), date_fin: fin.getTime() }).then((data: any) => {
      data.body.forEach((element) => {
        element.date_creation_commande = new Date(
          parseInt(element.date_creation_commande)
        );
      });
      this.orders = data.body;
      this.orders.forEach((element: any) => {
        element.etat_commande_int = element.etat_commande;
        element.etat_commande = STATE[element.etat_commande];
        element.show = 1;
      });
      this.orders.reverse();
      Loader.stopLoading();
    });
  }
  edit(order) {
    // ets.mode = '1';
    const dialogRef = this.dialog.open(OrderDetailsModal, {
      data: order,
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal === "close" || resultModal === null) {
        // alert('Closed');
        return;
      }
    });
  }
  delete(order) { }
  searcher() {
    var term = this.term.toUpperCase();
    // for (let index = 0; index < this.orders.length; index++) {
    this.orders.forEach((element) => {
      if (element.matricule_commande.toUpperCase().indexOf(term) > -1) {
        // console.log(element.matricule_commande.toUpperCase().indexOf(term));
        element.show = 1;
      } else {
        element.show = 0;
      }
    });
    //   this.departments[index].services.forEach(element => {
    //     if (element.nom_service.toUpperCase().indexOf(term) > -1) {
    //       // console.log(element.matricule_commande.toUpperCase().indexOf(term));
    //       element.show = 1;
    //     } else {
    //       element.show = 0;
    //     }
    //   });
    // }
  }
}
