import { Component, OnInit, Inject } from '@angular/core';
import { Loader } from "./../../helpers/loader";
import { NgForm } from '@angular/forms';
// import { CoreAccessPrototypeService } from '../../../core/services/core-access.prototype.services';
import {  MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';

import { Router } from '@angular/router';
import { CorePrototype } from '../../../core/services/core.prototype';
@Component({
  selector: 'dialog-settings',
  templateUrl: './dialog-settings.html',
  styleUrls: ['./dialog-settings.scss']
})
export class DialogSettings implements OnInit {
  /**
   * The log user keeper
   */
  user: any = {
    post_automatique: false,
    cdi: false,
    cdd: false,
    visible: false,
    visible_all: false,
    visible_employeur: false,
    cv_imprimable: false,
    id_user: 1
  };
  constructor( public dialogRef: MdcDialogRef<DialogSettings>, private service: CorePrototype, @Inject(MDC_DIALOG_DATA) public result: any) {
    console.log(result);
    
    this.user = result;
  }


  ngOnInit() {
   
  }
  submit(f: NgForm): void {
    console.log(f);
    console.log(this.user);
    
    let localUser = Loader.boolToString(this.user);
    Loader.load();
    this.service.post('update_settings', localUser).then((result: any) => {
      Loader.stopLoading();
      console.log(result);
    })
    this.dialogRef.close(Loader.stringToBool(this.user));
  }
  onChangePost(event){
    console.log(this.user);
  }
  onChangeProfil(event){
    console.log(this.user);

  }
}
