import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { MdcDialog } from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { STATE } from "../../../shared/model/codes";
import { Loader } from "../../../shared/helpers/loader";
import { OrderDetailsModal } from "../../../shared/components/order-details-modal/order-details-modal";
import { DialogBox } from "../../../shared/helpers/dialogBox";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent implements OnInit {
  customers = [];
  term = "";
  // ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(private service: CorePrototype, private dialog: MdcDialog) { }

  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_customers).then((data: any) => {
      data.body.forEach((element) => {
        element.date_creation_commande = new Date(
          parseInt(element.date_creation_commande)
        );
      });
      this.customers = data.body;
      this.customers.forEach((element: any) => {
        if (element.etat_client == "1") {
          element.etat_client_bool = true;
        } else {
          element.etat_client_bool = false;
        }
        element.show = 1;
      });
      this.customers.reverse();
      Loader.stopLoading();
    });
  }
  reset(user) {
    this.service
      .post(ENDPOINTS.post_update_customer, {
        id_client: user.id_client,
        password_client: "12345",
      })
      .then((data: any) => {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Mot de passe reinitialise",
        });
      });
  }
  edit(order) {
    // ets.mode = '1';
    const dialogRef = this.dialog.open(OrderDetailsModal, {
      data: order,
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal === "close" || resultModal === null) {
        // alert('Closed');
        return;
      }
    });
  }

  searcher() {
    var term = this.term.toUpperCase();
    // for (let index = 0; index < this.orders.length; index++) {
    this.customers.forEach((element) => {
      if ((element.nom_client?.toUpperCase() || '').indexOf(term) > -1) {
        // console.log(element.matricule_commande.toUpperCase().indexOf(term));
        element.show = 1;
      } else {
        element.show = 0;
      }
    });
  }
  onChange(user, order) {
    var value = 0;

    if (user.actif_bool == true) {
      value = 1;
    } else {
      value = 0;
    }
    Loader.loadBack();
    this.service
      .post(ENDPOINTS.post_update_customer, {
        id_client: user.id_client,
        etat_client: value,
      })
      .then((data: any) => {
        Loader.stoploadingBack();
        // DialogBox.showMessage(this.dialog, {
        //   title: "Message",
        //   body: "Utilisateur supprime",
        // });
      });
  }
  delete(user) {
    DialogBox.confirmationMessage(this.dialog, {
      title: "Confirmation",
      body: "Voulez vous vraiment supprimer cet utilisateur ?",
    }).then((result: any) => {
      if (result) {
        Loader.loadBack();
        this.service
          .post(ENDPOINTS.post_update_customer, {
            id_client: user.id_client,
            is_deleted_client: "1",
          })
          .then((data: any) => {
            Loader.stoploadingBack();
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Utilisateur supprime",
            });
          });
      }
    });
  }
}
