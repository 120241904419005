/**
 * For more info on the doc get to https://www.teslim.seeds.cm/docs.php
 */

export const ENDPOINTS = {
  post_user: "User/user",
  get_user: "User/user",
  get_users: "User/users",
  get_users_admin: "User/users_admin",
  post_update_pass_admin: "User/update_pass_admin",
  post_update_user: "User/update_user",
  post_login_user: "User/login",
  post_reset_pass: "User/reset_pass",
  post_delete_user: "User/delete_user",
  get_resto_by_type_produit: "User/resto_by_type_produit",
  post_update_admin_token: "User/update_admin_token",
  get_user_institutes: "User/user_institutes",

  post_update_order: "Orders/update_order",
  post_update_customer_order_lite: "Orders/update_customer_order_lite",
  post_customer_order: "Orders/customer_order",
  post_order: "Orders/order",
  get_order: "Orders/order",
  get_orders: "Orders/orders",
  get_marketplace_orders: "Orders/marketplace_orders",
  get_order_products_by_institute: "Orders/order_products_by_institute",
  get_orders_by_resto: "Orders/orders_by_resto",
  get_orders_for_resto_by_categorie: "Orders/orders_for_resto_by_categorie",
  get_orders_by_product: "Orders/orders_by_product",
  get_orders_by_service: "Orders/orders_by_service",
  get_orders_by_institute: "Orders/orders_by_institute",
  get_customer_orders: "Customer/customer_orders",
  get_customer_orders_marketplace: "Customer/customer_orders_marketplace",
  post_product: "Product/product",
  post_add_stock: "Product/add_stock",
  post_update_stock: "Product/update_stock",
  get_product_stocks: "Product/product_stocks",
  get_product: "Product/product_admin",
  get_products: "Product/products",
  get_produits_min: "Product/produits_min",
  get_products_by_type_produit: "Product/products_by_type_produit",
  post_update_product: "Product/update_product",
  get_owner_products: "Product/owner_products",

  post_service: "Service/service",
  get_service: "Service/service",
  get_services: "Service/services",
  post_update_service: "Service/update_service",

  post_categorie: "General/categorie",
  post_partenaire: "General/partenaire",
  post_testimonial: "General/testimonial",
  post_team: "General/team",
  post_categorie_produit: "General/categorie_product",
  post_categorie_service: "General/categorie_service",
  post_update_categorie: "General/update_categorie",
  post_update_testimonial: "General/update_testimonial",
  post_delete_testimonial: "General/delete_testimonial",
  post_delete_partenaire: "General/delete_partenaire",
  post_delete_team: "General/delete_team",
  post_update_categorie_produit: "General/update_categorie_product",
  post_update_categorie_service: "General/update_categorie_service",
  post_update_team: "General/update_update_team",
  post_send_messages_newsletter: "General/send_messages_newsletter",
  get_categories: "General/categories",
  get_testimonials: "General/testimonials",
  get_categorie_produit: "General/categorie_product",
  get_categorie_service: "General/categorie_service",
  get_categories_institute: "General/categories_institute",
  get_partenaires: "General/partenaires",
  get_newsletters: "General/newsletters",
  get_teams: "General/teams",

  post_categorie_boisson: "Products/categorie_boisson",
  post_update_categorie_boisson: "Products/update_categorie_boisson",
  post_fournisseur: "Product/fournisseur",
  post_update_fournisseur: "Product/update_fournisseur",
  post_delete_fournisseur: "Product/delete_fournisseur",
  get_categories_boisson: "Product/categories_boisson",
  get_fournisseurs_ets: "Product/fournisseurs_ets",

  post_categorie_colis: "Packet/categorie_colis",
  post_update_categorie_colis: "Packet/update_categorie_colis",
  get_categories_colis: "Packet/categories_colis",

  post_update_packet: "Packet/update_packet",
  post_delete_packet: "Packet/delete_packet",
  get_packets: "Packet/packets",
  get_packets_by_phone_and_state: "Packet/packets_by_phone_and_state",

  post_employee: "Employee/employee",
  post_add_role_employee: "Employee/add_role_employee",
  post_delete_role_employee: "Employee/delete_role_employee",
  post_update_employee: "Employee/update_employee",
  get_employee: "Employee/employee",
  get_employee_by_institution: "Employee/employee_by_institution",
  get_employee_orders: "Employee/employee_orders",
  get_roles: "Employee/roles",

  post_institute: "Institute/institute",
  post_update_institute: "Institute/update_institute",
  post_department: "Institute/department",
  post_update_department: "Institute/update_department",
  post_add_option_livraison: "Institute/add_option_livraison",
  get_option_livraison: "Institute/option_livraison",
  post_update_option_livraison: "Institute/update_option_livraison",
  post_delete_option_livraison: "Institute/delete_option_livraison",
  get_institute: "Institute/institute",
  get_institute_with_depts: "Institute/institute_with_depts",
  get_institute_by_matricule: "Institute/institute_by_matricule",
  get_department: "Institute/department",
  get_departments: "Institute/departments",
  get_departments_by_institute: "Institute/departments_by_institute",
  get_department_products: "Institute/department_products",
  get_department_products_by_categ: "Institute/department_products_by_categ",
  get_categories_and_products_by_department:
    "Institute/categories_and_products_by_department",
  get_categories_products_by_department:
    "Institute/categories_products_by_department",
  get_institute_products: "Institute/institute_products",
  get_institute_products_all: "Institute/institute_products_all",
  get_institute_employees: "Institute/institute_employees",
  get_min_product_institute: "Institute/min_product_institute",
  get_categories_products_by_institute:
    "Institute/categories_products_by_institute_admin",
  get_categories_and_products_by_institute:
    "Institute/categories_and_products_by_institute",
  get_institute_products_by_categ: "Institute/institute_products_by_categ",
  get_institute_products_in_squares: "Institute/institute_products_in_squares",
  get_ets_options_livraison: "Institute/ets_option_livraison_admin",
  get_institute_proforma: "Orders/institute_proforma",

  post_sortie_service: "Sortie/sortie_service",
  post_update_sortie_service: "Sortie/update_sortie_service",
  post_sortie_produit: "Sortie/sortie_produit",
  post_update_sortie_produit: "Sortie/update_sortie_produit",
  post_sortie_charge: "Sortie/sortie_charge",
  post_update_sortie_charge: "Sortie/update_sortie_charge",
  post_categorie_sortie: "Sortie/categorie_sortie",
  post_update_categorie_sortie: "Sortie/update_categorie_sortie",
  post_charge: "Sortie/charge",
  post_update_charge: "Sortie/update_charge",
  get_sortie_service: "Sortie/sorties_service",
  get_sortie_service_by_service: "Sortie/sorties_service_by_service",
  get_sortie_produit_by_produit: "Sortie/sorties_produit_by_produit",
  get_sortie_produit: "Sortie/sorties_produit",
  get_sortie_charge: "Sortie/sorties_charge",
  get_sortie_charge_by_charge: "Sortie/sorties_charge_by_charge",
  get_sorties: "Sortie/sorties",
  get_sorties_by_categorie_sortie: "Sortie/sorties_by_categorie_sortie",
  get_categories_sortie: "Sortie/categories_sortie",
  get_sorties_employe: "Sortie/sorties_employe",
  get_charges_etablissement: "Sortie/charges_etablissement",

  get_employe_stat: "Admin/employe_stat",
  get_employes_stat: "Admin/employes_stat",
  get_recette_produit: "Admin/recette_produit",
  get_recette_service: "Admin/recette_service",
  get_recettes_produits: "Admin/recettes_produits",
  get_summary: "Admin/summary",
  get_pertes_charges: "Admin/pertes_charges",
  get_pertes_service: "Admin/pertes_service",
  get_pertes_produit: "Admin/pertes_produit",
  get_pertes_produits: "Admin/pertes_produits",
  get_pertes_stock_produit: "Admin/pertes_stock_produit",
  get_pertes_stocks: "Admin/pertes_stocks",
  get_squares: "Admin/squares",
  get_squares_with_sub: "Admin/squares_with_sub",
  post_delete_image_une: "Admin/delete_image_une",
  post_update_image_une: "Admin/update_image_une",
  post_image_une: "Admin/image_une",
  get_images_une: "Admin/images_une",
  post_square: "Admin/square",
  post_marque: "Admin/marque",
  post_categorie_caree: "Admin/categorie_caree",
  get_categories_caree_all: "Admin/categories_caree_all",
  get_categories_caree: "Admin/categories_caree",
  post_update_square: "Admin/update_square",
  post_update_marque: "Admin/update_marque",
  post_update_categorie_caree: "Admin/update_categorie_caree",
  get_produit_critere_suggestion: "Admin/produit_critere_suggestion",
  get_produit_criteres_all: "Admin/produit_criteres_all",

  get_marques: "Admin/marques",
  post_critere_produit: "Admin/critere_produit",
  post_critere_produit_suggestion: "Admin/critere_produit_suggestion",
  post_update_critere_produit_suggestion: "Admin/update_critere_produit_suggestion",
  post_update_critere_produit: "Admin/update_critere_produit",
  get_categorie_produit_criteres: "Admin/categorie_produit_criteres",
  get_single_categorie_produit_criteres:
    "Admin/single_categorie_produit_criteres",
  get_customer: "Customer/customer",
  post_update_customer: "Customer/update_customer",
  get_customers: "Customer/customers",
  get_adresse_livraison: "Customer/adresse_livraison",
  post_check_valid_csv: "Product/check_valid_csv",
  get_products_in_squares: "General/products_in_squares",
  get_all_prods_min: "Admin/all_prods_min",
  post_push_service: "Admin/push_service",

  get_promotions: "Product/promotions",
  post_promotion: "Product/promotion",
  post_update_promotion: "Product/update_promotion",

  post_version: "Admin/version",
  post_update_version: "Admin/update_version",
  get_versions: "Admin/versions"
};
