import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";
// import { CreateCategorieModalComponent } from "../create-categorie-modal/create-categorie-modal.component";
import { ImageUtils } from "../../helpers/imageUtils";
import { DialogBox } from "../../helpers/dialogBox";
import { NgForm } from "@angular/forms";
import { User } from "../../helpers/user";
import { Loader } from "../../helpers/loader";
import { ENDPOINTS } from "../../model/endpoints";

@Component({
  selector: "app-ets-modal",
  templateUrl: "./ets-modal.component.html",
  styleUrls: ["./ets-modal.component.scss"],
})
export class EtsModalComponent implements OnInit {
  ets = {
    nom_etablissement: "",
    logo_etablissement: null,
    email_etablissement: "",
    telephone_etablissement_1: "",
    telephone_etablissement_2: "",
    mode: "0",
    description_etablissement: "",
    localisation_etablissement: "",
    id_utilisateur: "",
    id_etablissement: "",
    pay_in_advance: 0,
    auto_validation_order: 0,
    can_kitchen_handle_stock: 0,
    pay_in_advance_bool: false,
    auto_validation_order_bool: false,
    can_kitchen_handle_stock_bool: false,
    ets_level: 2,
    secteurs_activite: "",
  };
  fileLogo = null;
  carees = [];
  carees_global = [];
  selected_carees_global = [];
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<EtsModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data.mode === "0") {
      this.ets.mode = data.mode;
    } else {
      if (data.pay_in_advance == "1") {
        data.pay_in_advance_bool = true;
      } else {
        data.pay_in_advance_bool = false;
      }
      if (data.auto_validation_order == "1") {
        data.auto_validation_order_bool = true;
      } else {
        data.auto_validation_order_bool = false;
      }
      if (data.can_kitchen_handle_stock == "1") {
        data.can_kitchen_handle_stock_bool = true;
      } else {
        data.can_kitchen_handle_stock_bool = false;
      }

      this.ets = data;
    }
  }

  ngOnInit() {
    this.service.get(ENDPOINTS.get_squares).then((data: any) => {
      console.log(data);
      this.carees = data.body;
      this.carees_global = data.body;
    });
  }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.ets.logo_etablissement = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }
    var tmp_ets = Object.assign(this.ets, {});
    tmp_ets.secteurs_activite = "";
    this.selected_carees_global.forEach((element) => {
      tmp_ets.secteurs_activite =
        tmp_ets.secteurs_activite + " , " + element.nom_caree;
    });
    tmp_ets.id_utilisateur = User.getUserId();
    // tmp_ets.type_account = 2;
    if (tmp_ets.pay_in_advance_bool == true) {
      tmp_ets.pay_in_advance = 1;
    } else {
      tmp_ets.pay_in_advance = 0;
    }
    // if (tmp_ets.auto_validation_order_bool == true) {
    tmp_ets.auto_validation_order = 0;
    // } else {
    //   tmp_ets.auto_validation_order = 0;
    // }
    // if (tmp_ets.can_kitchen_handle_stock_bool == true) {
    tmp_ets.can_kitchen_handle_stock = 1;
    // } else {
    //   tmp_ets.can_kitchen_handle_stock = 0;
    // }

    console.log(tmp_ets);
    switch (tmp_ets.mode) {
      case "0":
        if (this.fileLogo !== null) {
          tmp_ets.logo_etablissement = this.fileLogo;
        } else {
          // delete cat.couverture_profil;
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour créer un etablissement",
          });
          return;
        }
        delete tmp_ets.mode;
        delete tmp_ets.id_etablissement;
        delete tmp_ets.pay_in_advance_bool;
        delete tmp_ets.auto_validation_order_bool;
        delete tmp_ets.can_kitchen_handle_stock_bool;
        // delete tmp_prod.id_type_produit;
        Loader.loadBack();

        this.service
          .post(ENDPOINTS.post_institute, tmp_ets)
          .then((data: any) => {
            console.log(data);
            Loader.stoploadingBack();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        if (this.fileLogo !== null) {
          tmp_ets.logo_etablissement = this.fileLogo;
        }
        delete tmp_ets.mode;
        // delete tmp_ets.categories;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        var final = {
          id_etablissement: tmp_ets.id_etablissement,
          logo_etablissement: tmp_ets.logo_etablissement,
          description_etablissement: tmp_ets.description_etablissement,
          telephone_etablissement_1: tmp_ets.telephone_etablissement_1,
          localisation_etablissement: tmp_ets.localisation_etablissement,
          email_etablissement: tmp_ets.email_etablissement,
          nom_etablissement: tmp_ets.nom_etablissement,
          pay_in_advance: tmp_ets.pay_in_advance,
        };
        Loader.loadBack();

        this.service
          .post(ENDPOINTS.post_update_institute, final)
          .then((data: any) => {
            console.log(data);
            Loader.stoploadingBack();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  addChipSquare(chip) {
    let off: Boolean = false;
    if (this.selected_carees_global.length <= 1) {
      if (chip.index !== -1) {
        this.selected_carees_global.forEach((value) => {
          if (value.id_caree === chip.value) {
            off = true;
          }
        });
        if (off === true) {
          return;
        }

        this.carees_global.forEach((element) => {
          if (element.id_caree === chip.value) {
            this.selected_carees_global.push(element);
          }
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Vous pouvez ajouter seulement 2 secteurs d'activites",
      });
    }
  }
  removeChipSquare(i) {
    // var deleted = this.selected_carees_global[i];
    // let off: Boolean = false;

    // if (this.produit.mode === "1") {
    //   this.removed_carees_global.forEach((value) => {
    //     if (value.id_caree === deleted.id_caree) {
    //       off = true;
    //     }
    //   });
    //   if (off === true) {
    //     this.selected_carees_global.splice(i, 1);
    //     return;
    //   }
    //   this.selected_carees_global.forEach((element) => {
    //     if (element.id_caree === deleted.id_caree) {
    //       if (element.old === true) {
    //         this.removed_carees_global.push(element);
    //       }
    //     }
    //   });
    // }

    this.selected_carees_global.splice(i, 1);
  }
}
