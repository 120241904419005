/**
 * @classdesc The global request handeler which can add headers,modify URLs before request and get some result errors
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name AppInterceptor
 */

import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { finalize, tap } from 'rxjs/operators';

import { BASE_URL_DEPLOY } from '../model/codes';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AppInterceptor implements HttpInterceptor {
    token: any;
    constructor() {
    }
    /**
     * This method can play the role of a constructor in an interceptor
     * @param {HttpRequest<any>} req - The actual URL to be executed
     * @param {HttpHandeler} next - Param to handle a request after execution
     * @returns {Observable<HttpEvent<any>>} - The result of the request in an observable
     */
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        let newReq;

        newReq = req.clone({
            url: BASE_URL_DEPLOY + req.url
        });

        if ((req.url.match(/assets/g) || []).length === 1) {
            newReq = req.clone({
                url: req.url,
            });
        }

        return next.handle(newReq).pipe(
            tap(
                // Succeeds when there is a response; ignore other events
                event => {
                    // Via this API, you get access to the raw event stream.
                    // Look for upload progress events.
                    // console.log(HttpEventType);
                    // console.log(event);
                    if (event.type === HttpEventType.DownloadProgress) {
                        // This is an upload progress event. Compute and show the % done:
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        console.log(`Data is ${percentDone}% uploaded.`);
                        // this.progress.sendMessage(event);
                    } else if (event instanceof HttpResponse) {
                        // console.log(event);
                        // console.log('Data is completely uploaded!');
                    }
                    return event;
                }
                ,
                // Operation failed; error is an HttpErrorResponse
                error => {
                    console.log(error);
                    return error;
                }
            ),
            // Log when response observable either completes or errors
            finalize(() => {
            })
        ).do(
            succ => { succ; },
            err => {
                return err;
            });

    }
}
