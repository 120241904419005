import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CorePrototype } from 'src/app/core/services/core.prototype';
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { NgForm } from '@angular/forms';
import { DialogBox } from '../../helpers/dialogBox';
import { ENDPOINTS } from '../../model/endpoints';
import { Loader } from '../../helpers/loader';
import { ImageUtils } from '../../helpers/imageUtils';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent implements OnInit {
  fileLogo = null;
  user = {
    couverture_profil: null,
    nom: '',
    email: '',
    telephone_user: '',
    localisation: '',
    id_user: '',
    description_profil: '',
    mode: '0',
    heure_ouverture: '',
    heure_fermeture: ''
  };
  constructor(public sanitizer: DomSanitizer, private service: CorePrototype, public dialog: MdcDialog, public dialogRef: MdcDialogRef<CreateUserModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    if (data.mode !== '0') {
      this.user = data;
    }
  }

  ngOnInit() {
  }
  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < (1 * 1024 * 1024)) {
        this.fileLogo = event.target.files[0];
        this.user.couverture_profil = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.fileLogo));
      } else {
        DialogBox.showMessage(this.dialog, { title: "Message", body: "Votre image doit peser moins d'un mega" });
      }

    } else {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer un fichier qui est une image image" });
    }

  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez remplir tous les champs" })
      return;
    }
    let cat: any = {
      couverture_profil: null,
      nom: this.user.nom,
      email: this.user.email,
      telephone_user: this.user.telephone_user,
      localisation: this.user.localisation,
      id_user: this.user.id_user,
      description_profil: this.user.description_profil,
      heure_ouverture: this.user.heure_ouverture,
      heure_fermeture: this.user.heure_fermeture,
      id_type_user: '2'
    };

    Loader.load();
    switch (this.user.mode) {
      case '0':
        delete cat.id_user;
        cat.password = '12345';
        if (this.fileLogo !== null) {
          cat.couverture_profil = this.fileLogo
        } else {
          // delete cat.couverture_profil;
          DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer une image pour creer un restaurant" });
          return;
        }
        this.service.post(ENDPOINTS.post_user, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.status === 'failed') {
            DialogBox.showMessage(this.dialog, { title: "Erreur", body: data.body });
            return;
          }
          this.dialogRef.close(data.body);
        })
        break;
      case '1':
        if (this.fileLogo !== null) {
          cat.couverture_profil = this.fileLogo
        } else {
          delete cat.couverture_profil;
        }
        this.service.post(ENDPOINTS.post_update_user, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.status === 'failed') {
            DialogBox.showMessage(this.dialog, { title: "Erreur", body: data.body });
            return;
          }
          this.dialogRef.close(data.body);
        })
        break;
      default:
        this.dialogRef.close(null);
        break;
    }
  }
}
