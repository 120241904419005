import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { MdcDialog } from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { STATE } from "../../../shared/model/codes";
import { Loader } from "../../../shared/helpers/loader";
import { OrderDetailsModal } from "../../../shared/components/order-details-modal/order-details-modal";
import { DialogBox } from "../../../shared/helpers/dialogBox";
import { Router } from "@angular/router";

@Component({
  selector: "app-marchands",
  templateUrl: "./marchands.component.html",
  styleUrls: ["./marchands.component.scss"],
})
export class MarchandsComponent implements OnInit {
  users = [];
  term = "";
  // ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    private dialog: MdcDialog,
    private router: Router
  ) {}

  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_users).then((data: any) => {
      data.body.forEach((element) => {
        element.date_creation_commande = new Date(
          parseInt(element.date_creation_commande)
        );
      });
      data.body.forEach((element: any) => {
        element.show = 1;
        if (element.actif == "1") {
          element.actif_bool = true;
        } else {
          element.actif_bool = false;
        }
      });
      this.users = data.body;

      Loader.stopLoading();
    });
  }

  onChange(user, order) {
    var value = 0;

    if (user.actif_bool == true) {
      value = 1;
    } else {
      value = 0;
    }
    Loader.loadBack();
    this.service
      .post(ENDPOINTS.post_update_user, {
        id_utilisateur: user.id_utilisateur,
        actif: value,
      })
      .then((data: any) => {
        Loader.stoploadingBack();
        // DialogBox.showMessage(this.dialog, {
        //   title: "Message",
        //   body: "Utilisateur supprime",
        // });
      });
  }
  delete(user) {
    DialogBox.confirmationMessage(this.dialog, {
      title: "Confirmation",
      body: "Voulez vous vraiment supprimer cet utilisateur ?",
    }).then((result: any) => {
      if (result) {
        Loader.loadBack();
        this.service
          .post(ENDPOINTS.post_update_user, {
            id_utilisateur: user.id_utilisateur,
            is_deleted: "1",
          })
          .then((data: any) => {
            Loader.stoploadingBack();
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Utilisateur supprime",
            });
          });
      }
    });
  }
  reset(user) {
    this.service
      .post(ENDPOINTS.post_update_user, {
        id_utlisateur: user.id_utlisateur,
        password: "12345",
      })
      .then((data: any) => {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Mot de passe reinitialise",
        });
      });
  }
  searcher() {
    var term = this.term.toUpperCase();
    // for (let index = 0; index < this.orders.length; index++) {
    this.users.forEach((element) => {
      if (element.nom.toUpperCase().indexOf(term) > -1) {
        // console.log(element.matricule_commande.toUpperCase().indexOf(term));
        element.show = 1;
      } else {
        element.show = 0;
      }
    });
  }
  goToEts(ets) {
    LocalStorage.setItem("currentEts", JSON.stringify(ets));
    this.router.navigate(["marchand_details", ets.id_etablissement]);
  }
}
