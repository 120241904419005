import { Component, OnInit } from "@angular/core";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { MdcDialog, MdcDialogRef } from '@angular-mdc/web';
import { DialogBox } from "../../../shared/helpers/dialogBox";
import { Loader } from "../../../shared/helpers/loader";
import { CritereProduitModal } from 'src/app/shared/components/critere-produit-modal/critere-produit-modal';
declare var jQuery: any;

@Component({
  selector: "app-critere-produit",
  templateUrl: "./critere-produit.component.html",
  styleUrls: ["./critere-produit.component.scss"],
})
export class CritereProduitComponent implements OnInit {
  options_livraison = [];
  criteres_produit = [];
  critere_produit = {
    id_categorie_caree: "",
    id_critere_produit: "",
    id_critere_produit_suggestion: "",
  };

  categories_carees = [];
  categories_carees_create = [];
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(private service: CorePrototype, private dialog: MdcDialog) { }

  ngOnInit() {
    // console.log(LocalStorage.getItem('currentEts'));
    Loader.load();
    // this.service
    //   .get(ENDPOINTS.get_criete, {
    //     id_etablissement: this.ets.id_etablissement,
    //   })
    //   .then((data: any) => {
    //     this.criteres_produit = data.body;
    //     Loader.stopLoading();
    //     console.log(data);
    //   });

    this.service
      .get(ENDPOINTS.get_produit_critere_suggestion)
      .then((data: any) => {
        this.categories_carees = data.body;
        Loader.stopLoading();
      });
    this.service.get(ENDPOINTS.get_categories_caree_all).then((data: any) => {
      this.categories_carees_create = data.body;
    });
    this.service
      .get(ENDPOINTS.get_produit_criteres_all)
      .then((data: any) => {
        this.criteres_produit = data.body;
        Loader.stopLoading();
      });
    this.service.get(ENDPOINTS.get_categories_caree_all).then((data: any) => {
      this.categories_carees_create = data.body;
    });
  }
  newCritere(critere_produit) {
    var dialogRef: MdcDialogRef<any> = null;

    dialogRef = this.dialog.open(CritereProduitModal, {
      data: critere_produit,
    });
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal !== null && resultModal !== "" && resultModal !== "close") {
        this.criteres_produit.push(resultModal);

      }
    });
  }
  new() {
    if (this.critere_produit.id_categorie_caree == "") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez choisir une categorie de produit",
      });
      return;
    }
    Loader.load();
    if (this.critere_produit.id_critere_produit_suggestion == "") {
      var copy = Object.assign({}, this.critere_produit);
      delete copy.id_critere_produit_suggestion;
      this.service
        .post(ENDPOINTS.post_critere_produit_suggestion, copy)
        .then((data: any) => {
          console.log(data);
          this.service
            .get(ENDPOINTS.get_produit_critere_suggestion)
            .then((data: any) => {
              this.categories_carees = data.body;
              Loader.stopLoading();
            });
        });
    } else {
      var copy = Object.assign({}, this.critere_produit);
      this.service
        .post(ENDPOINTS.post_update_critere_produit_suggestion, copy)
        .then((data: any) => {
          console.log(data);
          this.service
            .get(ENDPOINTS.get_produit_critere_suggestion)
            .then((data: any) => {
              this.categories_carees = data.body;
              Loader.stopLoading();
            });
          Loader.stopLoading();
        });
    }
  }
  edit(data) {
    this.critere_produit = data;
    jQuery("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  }
  delete(option_livraison) {
    DialogBox.confirmationMessage(this.dialog, {
      title: "Confirmation",
      body: "Etes vous sur de vouloir supprimer cette option de livraison",
    }).then((result: any) => {
      if (result) {
        this.service
          .post(ENDPOINTS.post_delete_option_livraison, {
            id_option_livraison: option_livraison.id_option_livraison,
          })
          .then((data: any) => {
            var index = this.options_livraison.findIndex(
              (item) =>
                item.id_option_livraison == option_livraison.id_option_livraison
            );
            this.options_livraison.splice(index, 1);
          });
      } else {
      }
    });
  }
  onChange(event, i) {
    console.log(event);
    if (event.checked === false) {
      this.options_livraison[i].etat_option_livraison = "0";
    } else {
      this.options_livraison[i].etat_option_livraison = "1";
    }
    Loader.load();
    this.service
      .post(ENDPOINTS.post_update_option_livraison, {
        id_option_livraison: this.options_livraison[i].id_option_livraison,
        etat_option_livraison: this.options_livraison[i].etat_option_livraison,
      })
      .then((data: any) => {
        console.log(data);
        Loader.stopLoading();
        // this.options_livraison[i] = data.body;
        // this.dialogRef.close(data.body);
      });
  }
}
