import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { PipeModule } from "../pipes/pipe.module";
import { FooterComponent } from "./footer/footer.component";

import { ConnectUserComponent } from "./connectUser/connect_user.component";
// import { ModalOccupationComponent } from "./modal-occupation/modal-occupation.component";

import { SidebarComponent } from "./sidebar/sidebar.component";
import { DialogConfirmation } from "./dialog-confirmation/dialog-confirmation";
import { DialogMessage } from "./dialog-message/dialog-message";
import { DialogSettings } from "./dialog-settings/dialog-settings";
import {
  MdcButtonModule,
  MdcTextFieldModule,
  MdcLinearProgressModule,
  MdcIconModule,
  MdcDrawerModule,
  MdcTabBarModule,
  MdcCardModule,
  MdcListModule,
  MdcSwitchModule,
  MdcDialogModule,
  MdcFormFieldModule,
  MdcCheckboxModule,
  MdcMenuSurfaceModule,
  MdcRadioModule,
  MdcSelectModule,
  MdcChipsModule,
  MdcElevationModule,
  MdcTypographyModule,
  MDCDataTableModule,
  MdcRippleModule,
} from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "./commande-detail-modal/commande-detail-modal.component";
import { ProduitEditModalComponent } from "./produit-edit-modal/produit-edit-modal.component";
import { ChartsModule } from "ng2-charts";
import { ColisDetailModalComponent } from "./colis-detail-modal/colis-detail-modal.component";
import { CreateUserModalComponent } from "./create-user-modal/create-user-modal.component";
import { InputTrimModule } from "ng2-trim-directive";
import { UpdatePassComponent } from "./update-pass/update-pass.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { EtsModalComponent } from "./ets-modal/ets-modal.component";

import { OptionLivraisonModalComponent } from "./option-livraison-modal/option-livraison-modal.component";
import { SelectCategoryMarketplaceModal } from "./select-category-marketplace-modal/select-category-marketplace-modal";
import { SelectCategoryAccountingModal } from "./select-category-accounting-modal/select-category-accounting-modal";
import { OrderDetailsModal } from "./order-details-modal/order-details-modal";
import { IconComponent } from "./icon/icon.component";
import { CustomerService } from "./customer-service/customer-service";
import { CriteresProduitModalComponent } from "./criteres-produit-modal/criteres-produit-modal.component";
import { CarresModalComponent } from "./carres-modal/carres-modal.component";
import { CategorieCareeModalComponent } from "./categorie_caree_modal/categorie-caree-modal.component";
import { CritereProduitModal } from './critere-produit-modal/critere-produit-modal';
import { VersionModalComponent } from './version-modal/version-modal.component';
import { PromoModalComponent } from './promo-modal/promo-modal.component';
import { ChipModule } from 'primeng/chip';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipeModule.forRoot(),
    MdcLinearProgressModule,
    MdcIconModule,
    InputTrimModule,
    MdcTextFieldModule,
    MdcButtonModule,
    MdcTabBarModule,
    MdcDrawerModule,
    MdcCardModule,
    MdcListModule,
    MdcSwitchModule,
    MdcDialogModule,
    MdcFormFieldModule,
    MdcCheckboxModule,
    MdcMenuSurfaceModule,
    MdcSelectModule,
    MdcRadioModule,
    MdcChipsModule,
    MdcElevationModule,
    MdcTypographyModule,
    ChartsModule,
    MDCDataTableModule,
    MdcRippleModule,
    ChipModule,
    AutoCompleteModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DialogConfirmation,
    DialogMessage,
    DialogSettings,
    CommandeDetailModalComponent,
    ProduitEditModalComponent,
    ColisDetailModalComponent,
    CreateUserModalComponent,
    UpdatePassComponent,
    NotificationsComponent,
    EtsModalComponent,
    OptionLivraisonModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    CriteresProduitModalComponent,
    CarresModalComponent,
    CustomerService,
    CategorieCareeModalComponent,
    CritereProduitModal,
    VersionModalComponent,
    PromoModalComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DialogConfirmation,
    DialogMessage,
    DialogSettings,
    CommandeDetailModalComponent,
    ProduitEditModalComponent,
    ColisDetailModalComponent,
    CreateUserModalComponent,
    UpdatePassComponent,
    NotificationsComponent,
    OptionLivraisonModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    CriteresProduitModalComponent,
    CarresModalComponent,
    CustomerService,
    CategorieCareeModalComponent,
    CritereProduitModal,
    VersionModalComponent,
    PromoModalComponent
  ],
  entryComponents: [
    DialogSettings,
    DialogConfirmation,
    DialogMessage,
    CommandeDetailModalComponent,
    ProduitEditModalComponent,
    ColisDetailModalComponent,
    CreateUserModalComponent,
    UpdatePassComponent,
    NotificationsComponent,
    EtsModalComponent,
    OptionLivraisonModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    CriteresProduitModalComponent,
    CarresModalComponent,
    CustomerService,
    CategorieCareeModalComponent,
    CritereProduitModal,
    VersionModalComponent,
    PromoModalComponent,
    SidebarComponent
  ],
})
export class SharedModule { }
