import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CorePrototype } from '../../../core/services/core.prototype';
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from '@angular-mdc/web';
import { NgForm } from '@angular/forms';
import { DialogBox } from '../../helpers/dialogBox';
import { ENDPOINTS } from '../../model/endpoints';
import { Loader } from '../../helpers/loader';

@Component({
  selector: 'app-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['./version-modal.component.scss']
})
export class VersionModalComponent implements OnInit {

  mode = 0;
  version = {
    id_app_squares: "1",
    lien_app: "",
    description: "",
    type: "1",
    type_text: "1",
    version: "",
    libelle: ""
  };
  fileLogo = null;
  constructor(
    private route: Router,
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<VersionModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null && data !== undefined) {
      this.version = data;
      this.mode = 1;
    }
  }

  ngOnInit() {
  }
  submit(form: NgForm) {
    var tmp_form = Object.assign({}, this.version);


    switch (this.mode) {
      case 0:
        if (this.fileLogo !== null && this.fileLogo !== undefined) {
          tmp_form.lien_app = this.fileLogo;
        } else {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer le fichier de l'application"
          });
          return;
        }
        delete tmp_form.id_app_squares;
        delete tmp_form.type_text;
        Loader.load();
        this.service
          .post(ENDPOINTS.post_version, tmp_form)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case 1:
        if (this.fileLogo !== null && this.fileLogo !== undefined) {
          tmp_form.lien_app = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer le fichier de l'application"
          // });
          // return;
        }
        Loader.load();
        delete tmp_form.type_text;

        this.service
          .post(ENDPOINTS.post_update_version, tmp_form)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  onchangeLogo(event) {
    console.log(event);
    console.log(event.target.files[0]);
    this.fileLogo = event.target.files[0];

  }
}
