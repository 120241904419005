import { Component, OnInit, SimpleChanges } from "@angular/core";
import { CorePrototype } from "../../services/core.prototype";
// import { ENDPOINTS } from "../../../shared/model/endpoints";
import { DateTableService } from "../../../shared/services/date-table.service";
import { MdcDialog } from "@angular-mdc/web";
// import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
// import { Loader } from "../../../shared/helpers/loader";
// import { DialogBox } from "../../../shared/helpers/dialogBox";
import { LocalStorage } from "../../../shared/helpers/localSortage";
// import { STATE } from "../../../shared/model/codes";
// import { mdiAccount } from "@mdi/js";
import { MessagingService } from '../../../shared/services/messaging.service';
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public pieChartType = "bar";
  public pieChartLegend = true;
  // public pieChartData: number[] = [300, 500, 100, 450];
  public pieChartLabels: any[] = [];
  public pieChartLabelsPertes: any[] = [];
  // public pieChartColors = [
  //   {
  //     backgroundColor: ['#4ca74f', '#e53a36', '#fea11d'],
  //   },
  // ];
  // lineChartData: any = [{ data: [0], label: 'Series A' }];
  pieData = [{ data: [0], label: "Siri" }];
  pieDataPertes = [{ data: [0], label: "Siri" }];

  ets = JSON.parse(LocalStorage.getItem("currentEts"));

  employeData = [{ data: [0], label: "Siri" }];

  actualWeek: any[] = [];

  actualDate: any;
  sum_ventes = 0;
  sum_pertes = 0;
  gains_net = 0;

  ets_recette_data = [];
  ets_pertes_charges = [];

  ets_pertes_produits = [];
  ets_pertes_stocks = [];

  ets_recette_graph: any = [
    {
      nom_departement: "",
      produit: {
        chart_labels: [],
        data_graph: [{ data: [0], label: "Siri" }],
      },
      service: {
        chart_labels: [],
        data_graph: [{ data: [0], label: "Siri" }],
      },
    },
  ];

  ets_pertes_produits_graph: any = [
    {
      nom_departement: "",
      produit: {
        chart_labels: [],
        data_graph: [{ data: [0], label: "Siri" }],
      },
      service: {
        chart_labels: [],
        data_graph: [{ data: [0], label: "Siri" }],
      },
    },
  ];

  ets_pertes_stocks_graph: any = [
    {
      nom_departement: "",
      produit: {
        chart_labels: [],
        data_graph: [{ data: [0], label: "Siri" }],
      },
    },
  ];
  date_debut = "2019/12/12";
  date_fin = "2019/12/12";
  display_date_debut = "2019/12/12";
  display_date_fin = "2019/12/12";
  summary = {
    nombre_produits: 0,
    ventes_total: 0,
    commandes_annule: 0,
    commandes_ok: 0,
  };
  icon = "";
  message;
  logged = false;
  token: any = null;
  messages: any[] = [];
  constructor(
    public service: CorePrototype,
    private messagingService: MessagingService

  ) { }

  ngOnInit() {
    // console.log(mdiAccount);
    // this.icon = mdiAccount;
    // this.actualDay(new Date(), {});
    // console.log(this.actualWeek);
    // this.date_debut = this.actualWeek[0].dayDate;
    // this.date_fin = this.actualWeek[6].dayDate;
    // this.display_date_debut = this.actualWeek[0].dayDate;
    // this.display_date_fin = this.actualWeek[6].dayDate;
    // Highcharts.chart(this.options);
    // Highcharts
    // this.getSummary();
    const userId = 'admin_123';
    this.token = this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.subscribe((data) => {
      if (data !== null) {
        console.log(data);
        this.messages.push(data);
        console.log(this.messages);
      }

    })
  }

  ngOnChanges(changes: SimpleChanges): void { }
  updateGraph() { }
}
