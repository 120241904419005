import { Component, OnInit } from "@angular/core";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { MdcDialog } from "@angular-mdc/web";
import { DialogBox } from "../../../shared/helpers/dialogBox";
import { Loader } from "../../../shared/helpers/loader";
import { OptionLivraisonModalComponent } from "src/app/shared/components/option-livraison-modal/option-livraison-modal.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { JSONToCSV } from "../../../shared/helpers/JsonToCSV";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.scss"],
})
export class NewsletterComponent implements OnInit {
  options_livraison = [];
  public Editor = ClassicEditor;
  public model = {
    message: "<p>Hello, world!</p>",
    objet: "",
  };
  public config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "link",
        "bulletedList",
        "numberedList",
        "alignment",
        "fontColor",
        "fontBackgroundColor",
        "fontSize",
        "highlight",
        "code",
        "|",
        "indent",
        "outdent",
        "|",
        "CKFinder",
        "imageUpload",
        "blockQuote",
        "insertTable",
        "codeBlock",
        "pageBreak",
        "mediaEmbed",
        "undo",
        "redo",
      ],
    },
    language: "fr",
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
      ],
    },
    licenseKey: "",
    simpleUpload: {
      uploadUrl: "https://www.learn.seeds.cm" + "/Formation/handle_image_new",
    },
    allowedContent: true,
  };
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(private service: CorePrototype, private dialog: MdcDialog) {}

  ngOnInit() {}

  send() {
    this.service
      .post(ENDPOINTS.post_send_messages_newsletter, this.model)
      .then((data: any) => {
        DialogBox.showMessage(this.dialog, {
          title: "message",
          body: "Message envoye",
        });
      });
  }
  getList() {
    this.service.get(ENDPOINTS.get_newsletters).then((data: any) => {
      var doc = new JSONToCSV(data.body, "Les mails", "Les mails");
      doc.downloader();
    });
  }
  editorReady(event) {
    console.log(event);
  }
  editorError(event) {
    console.log(event);
  }
}
