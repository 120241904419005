import { Component, OnInit, Inject } from '@angular/core';
import { CorePrototype } from '../../../core/services/core.prototype';
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { ImageUtils } from '../../helpers/imageUtils';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogBox } from '../../helpers/dialogBox';
import { NgForm } from '@angular/forms';
import { ENDPOINTS } from '../../model/endpoints';
import { Loader } from '../../helpers/loader';
import { DateUtils } from '../../helpers/dateUtils';

@Component({
  selector: 'app-promo-modal',
  templateUrl: './promo-modal.component.html',
  styleUrls: ['./promo-modal.component.scss']
})
export class PromoModalComponent implements OnInit {
  promotion = {
    id_promotion: '',
    nom_promotion: "",
    description_promotion: "",
    couverture_promotion: null,
    couverture_promotion_min: "",
    date_ajout_promotion: "",
    date_debut_promotion: null,
    date_fin_promotion: null,
    matricule_promotion: "",
    etat_promotion: "",
    produits: []
  }
  fileLogo = null;
  mode = 0;
  produits = [];
  selected_produits_global = [];
  selectedProduits = [];
  filteredProducts = [];
  removed_produits_global = [];
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<PromoModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null && data !== undefined) {
      this.promotion = Object.assign({}, data);
      console.log(this.promotion);
      this.promotion.date_debut_promotion = DateUtils.dateFormat2Ext(new Date(parseInt(this.promotion.date_debut_promotion)));
      this.promotion.date_fin_promotion = DateUtils.dateFormat2Ext(new Date(parseInt(this.promotion.date_fin_promotion)));
      this.mode = 1;
    }
  }

  ngOnInit() {
    this.service
      .get(ENDPOINTS.get_produits_min)
      .then((data: any) => {
        console.log(data);
        this.produits = data.body;
      });
    this.promotion.produits.forEach(element => {
      element.old = true;
    });
    this.selected_produits_global = this.promotion.produits.slice();
  }
  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.promotion.couverture_promotion = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega"
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image"
      });
    }
  }
  filterRoleFn(event) {

    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.produits.length; i++) {
      let role = this.produits[i];
      if (role.nom_produit.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(role);
      }
    }

    this.filteredProducts = filtered;
    console.log(this.filteredProducts);
  }
  selectedRoleFn(value) {
    console.log(value);
    // console.log(this.selectedProduct);
    // this.commande.id_produit = value.id_produit;
    this.addChip(value.id_produit);
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    var tmp_ets = Object.assign(this.promotion, {});
    if (this.selected_produits_global.length === 0) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Choissisez au moins 1 role"
      });
      return;
    } else {
      tmp_ets["produits[]"] = [];
      if (this.mode === 0) {
        this.selected_produits_global.forEach(element => {
          tmp_ets["produits[]"].push(element.id_produit);
        });
      } else {
        var index = 0;
        console.log(this.promotion);
        this.selected_produits_global.forEach(element => {
          var same: any = false;
          // let that = this;
          // TO verifiy if the cat has not already been added
          this.promotion.produits.forEach(elementProd => {
            if (element.id_produit === elementProd.id_produit) {
              same = true;
              // debugger;
            }
          });
          if (same == false) {
            tmp_ets["produits[]"].push(element.id_produit + "@1");
            console.log(element);
            // debugger;
          }
          index++;
        });
        // console.log(this.removed_produits_global);
        this.removed_produits_global.forEach(element => {
          var same: any = false;
          // let that = this;
          // to verify if the cat to be removed has not alredy been added
          this.selected_produits_global.forEach(elementProd => {
            if (element.id_produit === elementProd.id_produit) {
              same = true;
            }
          });
          if (same == false) {
            tmp_ets["produits[]"].push(element.id_produit + "@2");
          }
          index++;
        });
      }
      console.log(tmp_ets);
      // return;
      switch (this.mode) {
        case 0:
          if (this.fileLogo !== null) {
            tmp_ets.couverture_promotion = this.fileLogo;
          } else {
            // tmp_ets.couverture_promotion = this.ets_actuel.logo_etablissement;

            // delete cat.couverture_profil;
            DialogBox.showMessage(this.dialog, {
              title: "Message",
              body: "Veuillez inserer une image pour créer un promotion"
            });
            return;
          }
          delete tmp_ets.id_promotion;
          // delete tmp_ets.produits;
          // delete tmp_prod.id_type_produit;
          tmp_ets.date_debut_promotion = new Date(tmp_ets.date_debut_promotion).getTime();
          tmp_ets.date_fin_promotion = new Date(tmp_ets.date_fin_promotion).getTime();
          Loader.load();

          this.service
            .post(ENDPOINTS.post_promotion, tmp_ets)
            .then((data: any) => {
              console.log(data);
              Loader.stopLoading();
              this.dialogRef.close(data.body);
            });
          break;
        case 1:
          if (this.fileLogo !== null) {
            tmp_ets.couverture_promotion = this.fileLogo;
          }
          delete tmp_ets.produits;
          // delete tmp_prod.mode;
          // delete tmp_prod.mode;
          // delete tmp_prod.mode;
          var final = {
            id_promotion: tmp_ets.id_promotion,
            couverture_promotion: tmp_ets.couverture_promotion,
            nom_promotion: tmp_ets.nom_promotion,
            description_promotion: tmp_ets.description_promotion
          };
          final["produits[]"] = tmp_ets["produits[]"];
          // console.log(tmp_ets.date_debut_promotion);
          // console.log(tmp_ets.date_fin_promotion);
          // console.log(final);
          // return;
          Loader.load();

          this.service
            .post(ENDPOINTS.post_update_promotion, final)
            .then((data: any) => {
              console.log(data);
              Loader.stopLoading();
              this.promotion = data.body;
              this.dialogRef.close(data.body);
            });
          break;

        default:
          this.dialogRef.close(null);
          break;
      }
    }
  }

  addChip(chip) {
    console.log(chip);
    let off: Boolean = false;
    if (chip.index !== -1) {
      this.selected_produits_global.forEach(value => {
        if (value.id_produit === chip) {
          off = true;
        }
      });
      if (off === true) {
        return;
      }

      this.produits.forEach(element => {
        if (element.id_produit === chip) {
          this.selected_produits_global.push(element);
        }
      });
    }
    console.log(this.selected_produits_global);
  }
  removeChip(i) {
    console.log(i);
    var deleted = this.selected_produits_global[i];
    let off: Boolean = false;

    if (this.mode == 1) {
      this.removed_produits_global.forEach(value => {
        if (value.id_produit === deleted.id_produit) {
          off = true;
        }
      });
      if (off === true) {
        this.selected_produits_global.splice(i, 1);
        console.log(this.removed_produits_global);

        return;
      }
      this.selected_produits_global.forEach(element => {
        console.log(element);
        if (element.id_produit === deleted.id_produit) {
          if (element.old === true) {
            this.removed_produits_global.push(element);
          }
        }
      });
    }
    this.selected_produits_global.splice(i, 1);
  }
}
