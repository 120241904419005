import { Injectable } from "@angular/core";
import { RequestService } from "../../shared/services/request.service";
import { codes } from "../../shared/model/codes";
import { Loader } from "../../shared/helpers/loader";
import { ENDPOINTS } from "../../shared/model/endpoints";
import { DialogBox } from "../../shared/helpers/dialogBox";
import { MdcDialog } from "@angular-mdc/web";
import { MethodUtils } from "src/app/shared/helpers/methodUtils";
declare var jQuery: any;
@Injectable()
export class CorePrototype {
  /**
   * @var Object this variable contains all the url of the module
   */
  public urls = {};
  public codes = {};

  constructor(private request: RequestService, private dialog: MdcDialog) {
    this.init();
    // this.generateFunctions();
  }
  init() {
    this.urls = ENDPOINTS;
    this.codes = codes;
    // this.codes.101;
  }
  /**
   * @param url The current get url
   * @param params the get parameters for the request
   * @return Promise The current request promise
   */
  get(url, params = null) {
    let parameters = "",
      that = this;
    for (let prop in params) {
      parameters += "/" + prop + "/" + params[prop];
      // parameters += "/" + params[prop];
    }
    let link = url + parameters;
    // console.log(link);
    return new Promise(function (resolve, reject) {
      that.request.get(link).subscribe({
        next(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    }).catch((error) => {
      console.log(error);
      // Loader.stopLoading();
      DialogBox.showMessage(this.dialog, {
        title: "Erreur",
        body: "Problem avec le serveur",
      });
      if (error.status === 401) {
        // jQuery('#EmergencyModal').modal();
        jQuery("#EmergencyModal").modal("open");
      }
    });
  }
  /**
   * @param url The current get url
   * @param params the get parameters for the request
   * @return Promise The current request promise
   */
  delete(url, params = null) {
    let parameters = "",
      that = this;
    for (let prop in params) {
      parameters += "/" + prop + "/" + params[prop];
    }
    let link = this.urls[url] + parameters;
    // console.log(link);
    return new Promise(function (resolve, reject) {
      that.request.delete(link).subscribe({
        next(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @param url The current get url
   * @param params the get parameters for the request
   * @return Promise The current request promise
   */
  post(url, params = null, parameters = null) {
    let that = this;
    // let link = this.urls[url];
    // console.log(params);
    // if (parameters !== null) {
    //     link = link + '/' + parameters;
    // }
    let data = MethodUtils.toFormDatas(params);
    console.log(data);
    return new Promise(function (resolve, reject) {
      that.request.post(url, data).subscribe({
        next(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    }).catch((error) => {
      console.log(error);
      if (error.status === 401) {
        // jQuery('#EmergencyModal').modal();
        jQuery("#EmergencyModal").modal("open");
      }
    });
  }
  /**
   * @param url The current get url
   * @param params the get parameters for the request
   * @return Promise The current request promise
   */
  put(url, params = null) {
    let that = this;
    let link = this.urls[url];
    console.log(params);
    return new Promise(function (resolve, reject) {
      that.request.put(link, params).subscribe({
        next(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    }).catch((error) => {
      console.log(error);
    });
  }
}
