import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import {
  MdcDialogRef,
  MDC_DIALOG_DATA,
  MdcDialog,
  MdcSelectChange,
} from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { ImageUtils } from "../../helpers/imageUtils";
import { LocalStorage } from "../../helpers/localSortage";

@Component({
  selector: "app-categorie-caree-modal",
  templateUrl: "./categorie-caree-modal.component.html",
  styleUrls: ["./categorie-caree-modal.component.scss"],
})
export class CategorieCareeModalComponent implements OnInit {
  categorie_caree: any = {
    id_categorie_caree: "",
    mode: "1",
    id_caree: "",
    nom_categorie_caree: "",
    description_categorie_caree: "",
    taxo: "",
    image_categorie_caree: null,
    perc_commission: 10,
    google_product_category: 0,
    carres: [],
  };
  carres = [];
  fileLogo: any = null;
  deps = [];
  type_user = "0";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<CategorieCareeModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    switch (data.mode) {
      case "0":
        this.categorie_caree.mode = data.mode;
        this.categorie_caree.carres = data.carres;
        break;

      case "1":
        this.categorie_caree = data;
        break;

      default:
        break;
    }
  }
  ngOnInit() { }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.categorie_caree.image_categorie_caree = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }

    console.log(this.categorie_caree);
    switch (this.categorie_caree.mode) {
      case "0":
        var cat = Object.assign({}, this.categorie_caree);

        if (this.fileLogo !== null) {
          cat.image_categorie_caree = this.fileLogo;
        } else {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour creer une categorie",
          });
          return;
        }

        if (
          cat.id_caree == null ||
          cat.id_caree == "null" ||
          cat.id_caree == ""
        ) {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez choisir un carre parent",
          });
          return;
        } else {
        }
        delete cat.mode;
        delete cat.taxo;
        delete cat.carres;

        Loader.load();
        // cat.id_etablissement = this.ets.id_etablissement;

        this.service
          .post(ENDPOINTS.post_categorie_caree, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        var cat = Object.assign({}, this.categorie_caree);

        if (this.fileLogo !== null) {
          cat.image_categorie_caree = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer une image pour creer une categorie"
          // });
          // return;
        }
        if (
          cat.id_caree == null ||
          cat.id_caree == "null" ||
          cat.id_caree == ""
        ) {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez choisir un carre parent",
          });
          return;
        } else {
        }
        delete cat.mode;
        delete cat.carres;
        delete cat.taxo;

        // cat.id_etablissement = this.ets.id_etablissement;

        Loader.load();

        this.service
          .post(ENDPOINTS.post_update_categorie_caree, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  selectChange(event: MdcSelectChange) {
    console.log(event);
    // if (event.source.ngControl.control.touched == false) {
    // } else {
    this.carres = this.categorie_caree.carres[event.index - 1].sous_caree;
    // }
  }
}
