import { Component, OnInit } from "@angular/core";
import { ImageUtils } from "../../../shared/helpers/imageUtils";
import { DialogBox } from "../../../shared/helpers/dialogBox";
import { MdcDialog } from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { STATE } from "../../../shared/model/codes";

import { Loader } from "../../../shared/helpers/loader";

@Component({
  selector: "app-slide-images",
  templateUrl: "./slide-images.component.html",
  styleUrls: ["./slide-images.component.scss"],
})
export class SlideImagesComponent implements OnInit {
  // ets = JSON.parse(LocalStorage.getItem("currentEts"));
  images_une = [];
  teams = [];
  partenaires = [];
  constructor(
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public service: CorePrototype
  ) {}
  image_une = {
    lien_image_une: "",
    image_file: null,
    image_une: null,
  };
  file = null;
  ngOnInit() {
    this.service.get(ENDPOINTS.get_images_une).then((data: any) => {
      this.images_une = data.body;
    });
  }
  onchange(event, label) {
    var file = event.target.files[0];
    console.log(file);
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1024 * 1024) {
        if (isNaN(label)) {
          this.image_une.image_une = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
          this.image_une.image_file = file;
        } else {
          this.images_une[
            label
          ].image_une = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
          this.images_une[label].image_une_file = file;
        }
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  update(image_une, index) {
    Loader.load();
    var obj: any = {};
    obj.lien_image_une = image_une.lien_image_une;

    if (image_une.image_une_file) {
      obj.image_une = image_une.image_une_file;
    } else {
      obj.image_une = image_une.image_une;
    }
    obj.id_image_une = image_une.id_image_une;

    this.service
      .post(ENDPOINTS.post_update_image_une, obj)
      .then((data: any) => {
        console.log(data);
        Loader.stopLoading();
        this.images_une[index] = data.body;
      });
  }
  add() {
    var obj: any = {};
    obj.lien_image_une = this.image_une.lien_image_une;

    if (this.image_une.image_file === null) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Vous devez envoyer une image",
      });
      return;
    }
    {
      obj.image_une = this.image_une.image_file;
    }
    Loader.load();
    this.service.post(ENDPOINTS.post_image_une, obj).then((data: any) => {
      console.log(data);
      Loader.stopLoading();
      this.images_une.push(data.body);
      this.image_une = {
        lien_image_une: "",
        image_file: null,
        image_une: null,
      };
    });
  }
  delete(image_une, index) {
    Loader.load();
    var obj: any = {};

    obj.id_image_une = image_une.id_image_une;

    this.service
      .post(ENDPOINTS.post_delete_image_une, obj)
      .then((data: any) => {
        console.log(data);
        Loader.stopLoading();
        this.images_une.splice(index, 1);
      });
  }
}
