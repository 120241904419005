export const codes = {
  "100": "Succès",
  "101": "Erreur interne au serveur",
  "102": "Service indisponible",
  "104": "Utilisateur bloqué",
  "105": "Compte inexistant",
  "106": "Déjà connecter",
  "107": "Opération en cours de traitement",
  "108": "Mot de passe incorrect",
  "109": "Délégué inexistant",
  "110": "Tentative d’ajouter une information qui existe déjà",
  "111": "Compte commission vide",
  "112": "Montant supérieur au solde du compte commission",
  "113": "Compte insuffisant",
  "114": "Utilisateur deconnecter",
  "115": "Echec de l’opération",
  "116": "Transaction inexistante",
  "117": "Echec",
};

export const STATE = {
  "1": "Nouveau",
  "2": "Validé par la cuisine",
  "3": "Rejete par la cuisine",
  "4": "Payé",
  "5": "Annulé par le client",
  "6": "Annulé par l'admin",
  "7": "Pret",
  "8": "Réception éffective",
  "9": "Réception inéffective",
  "10": "Devis de livraison",
};
export const STATE_PACKET = {
  "1": "Nouveau",
  "2": "Validé par le client",
  "3": "En cours d'execution",
  "4": "Annulé par le client",
  "5": "Rejété par teslim",
  "6": "En cours d'acheminement",
  "7": "Paquet acheminé",
  "8": "Réception éffective par le client",
  "9": "Réception inéffective",
  "10": "Devis de livraison",
};
export const NOTIF_CODE = {
  "101": "Comparaison",
  "102": "Produits bientôt disponible",
  "105": "Produits particuliers",
}
export const UNITE_CRITERES = [{ label: "Hertz", val: "Hertz" }, { label: "Go", val: "Go" }];

// "103": "Nouveau produit du marchand",
//   "104": "Nouveau produit du carré",
// export const BASE_URL = "http://localhost/publish/api";
export const BASE_URL = "https://www.adminsquares.seeds.cm/";
export const BASE_URL_DEPLOY = "https://www.squares.seeds.cm/";
// export const BASE_URL_DEPLOY = "http://www.localhost/squareApi/";
export const LOGO_URL = "https://www.squares.cm/assets/logo180x180.jpg";
// export const BASE_URL = "http://localhost:4200";
