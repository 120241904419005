import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { ImageUtils } from "../../helpers/imageUtils";
import { LocalStorage } from "../../helpers/localSortage";

@Component({
  selector: "app-criteres-produit-modal",
  templateUrl: "./criteres-produit-modal.component.html",
  styleUrls: ["./criteres-produit-modal.component.scss"],
})
export class CriteresProduitModalComponent implements OnInit {
  option_livraison: any = {
    id_option_livraison: "",
    mode: "1",
    id_etablissement: "",
    nom_option_livraison: "",
    description_option_livraison: "",
    prix_option_livraison: "",
    logo_option_livraison: null,
    etat_option_livraison: "1",
    is_deleted_option_livraison: "0",
  };
  fileLogo: any = null;
  deps = [];
  type_user = "0";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<CriteresProduitModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    switch (data.mode) {
      case "0":
        this.option_livraison.mode = data.mode;
        break;

      case "1":
        this.option_livraison = data;
        break;

      default:
        break;
    }
  }
  ngOnInit() {}

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.option_livraison.logo_option_livraison = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }

    console.log(this.option_livraison);
    switch (this.option_livraison.mode) {
      case "0":
        var cat = Object.assign({}, this.option_livraison);

        if (this.fileLogo !== null) {
          cat.logo_option_livraison = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer une image pour creer une categorie"
          // });
          // return;
        }
        delete cat.mode;

        Loader.load();
        cat.id_etablissement = this.ets.id_etablissement;

        this.service
          .post(ENDPOINTS.post_add_option_livraison, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        var cat = Object.assign({}, this.option_livraison);

        if (this.fileLogo !== null) {
          cat.logo_option_livraison = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer une image pour creer une categorie"
          // });
          // return;
        }
        delete cat.mode;
        cat.id_etablissement = this.ets.id_etablissement;

        Loader.load();

        this.service
          .post(ENDPOINTS.post_update_option_livraison, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  radioChange(event) {
    console.log(event);
    this.type_user = event.value;
    // if(event.target.value === )
  }
}
