import { Component, OnInit, Inject } from '@angular/core';
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { CommandeDetailModalComponent } from '../commande-detail-modal/commande-detail-modal.component';
import { ColisDetailModalComponent } from '../colis-detail-modal/colis-detail-modal.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  messages: any[] = [];
  livraisons: any[] = [];
  nourittures: any[] = [];
  constructor(public dialog: MdcDialog, public dialogRef: MdcDialogRef<NotificationsComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    if (data !== null) {
      this.messages = data;
      this.messages.forEach(element => {
        if (element.data.title === '100') {
          this.nourittures.push(element);
        } else {
          this.livraisons.push(element);
        }
      })
    }
  }

  ngOnInit() {
  }
  empty() {
    this.dialogRef.close('empty');
  }
  openModal(param) {
    if (param.data.title === '100') {
      const dialogRef = this.dialog.open(CommandeDetailModalComponent, {
        data: param.data.body
      });

      dialogRef.afterClosed().subscribe(resultModal => {
        console.log(resultModal);
        if (resultModal !== 'close' || resultModal !== null) {
          // this.finishUpdate(resultModal);
        }
      });
    } else {
      const dialogRef = this.dialog.open(ColisDetailModalComponent, {
        data: param.data.body
      });

      dialogRef.afterClosed().subscribe(resultModal => {
        console.log(resultModal);
        if (resultModal !== 'close' || resultModal !== null) {
          // this.finishUpdate(resultModal);
        }
      });
    }
  }
}
