import { Component, Inject } from "@angular/core";
import { MdcDialogRef } from "@angular-mdc/web";

import { Router } from "@angular/router";
@Component({
  selector: "customer-service",
  templateUrl: "./customer-service.html",
  styleUrls: ["./customer-service.scss"],
})
export class CustomerService {
  /**
   * The log user keeper
   */
  message: any = {};

  constructor(
    private route: Router,
    public dialogRef: MdcDialogRef<CustomerService>
  ) {
    // this.message = data;
  }

  submit(): void {
    this.dialogRef.close(this.message);
  }
}
