import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  AfterViewInit,
} from "@angular/core";
import { User } from "../../helpers/user";
import { LocalStorage } from "../../helpers/localSortage";
import { MessagingService } from "../../services/messaging.service";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { UpdatePassComponent } from "../update-pass/update-pass.component";
import { MdcDialog } from "@angular-mdc/web";
import { NotificationsComponent } from "../notifications/notifications.component";
import { CommandeDetailModalComponent } from "../commande-detail-modal/commande-detail-modal.component";
import { ColisDetailModalComponent } from "../colis-detail-modal/colis-detail-modal.component";
import { Router } from "@angular/router";
// import { StockGeneralModalComponent } from "../stock-general-modal/stock-general-modal.component";
import { CustomerService } from "../customer-service/customer-service";
declare var jQuery: any;
@Component({
  selector: "header-component",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * @var Boolean tell is wheather or not the global loader should be launch
   */
  @Input("loading") loading;

  /**
   * @var Boolean tell if a user is authenticate or not
   */
  isLogin: Boolean = false;
  open: Boolean = false;
  token: any = null;
  messages: any[] = [];
  destinations = [
    {
      title: "",
      menus: [
        {
          label: "Tableau de bord",
          icon: "mdiMonitorDashboard",
          route: "home",
          index: 4,
        },
        {
          label: "Marchands",
          icon: "mdiAccountCash",
          route: "marchands",
          index: 2,
        },

        {
          label: "Produits / Services",
          icon: "mdiBriefcaseVariant",
          route: "articles",
          index: 6,
        },
        {
          label: "Promotions",
          icon: "mdiSale",
          route: "promotions",
          index: 20,
        },
        {
          label: "Lien de paiement",
          icon: "mdiCreditCardCheck",
          route: "pay-link",
          index: 22,
        },
        {
          label: "Clients",
          icon: "mdiAccountCircle",
          route: "clients",
          index: 17,
        },
        {
          label: "Commandes",
          icon: "mdiCartOutline",
          route: "commandes",
          index: 17,
        },
      ],
    },
    {
      title: "Contenu",
      menus: [
        {
          label: "Carres",
          icon: "mdiCropSquare",
          route: "carres",
          index: 21,
        },
        {
          label: "Slides images",
          icon: "mdiPlayBoxOutline",
          route: "slide_images",
          index: 31,
        },
        {
          label: "Criteres produits",
          icon: "mdiOrderBoolDescendingVariant",
          route: "critere_produits",
          index: 32,
        },
        {
          label: "Notifications",
          icon: "mdiBellRing",
          route: "notifs",
          index: 34,
        },
        {
          label: "Versions",
          icon: "mdiAndroid",
          route: "versions",
          index: 35,
        },
      ],
    },
    {
      title: "Contact",
      menus: [
        {
          label: "Newsletter",
          icon: "mdiAccount",
          route: "newsletter",
          index: 22,
        },
      ],
    },
  ];

  user = User.getUser();
  icon = "mdiAccountConvert";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    private router: Router
  ) {}

  ngOnInit() {
    jQuery("#perso-backdrop").hide();

    // if (this.ets.ets_level === "2") {
    //   // this.destinations =
    // }
    console.log(this.ets);
  }
  ngAfterViewInit() {
    var that = this;

    var div = document.getElementById("perso-backdrop");
    // tslint:disable-next-line:only-arrow-functions
    div.addEventListener("click", function () {
      that.openMenu();
    });
    this.destinations.forEach((menus: any) => {
      // console.log(menus);
      menus.menus.forEach((element) => {
        if ("/" + element.route === this.router.url) {
          jQuery("." + element.route).addClass("mdc-list-item--activated");
          // console.log('#mdc-list-item-' + element.index);
        } else {
          jQuery("." + element.route).removeClass("mdc-list-item--activated");
        }
      });
    });
    // if (this.ets.has_global_config === 1) {
    // } else {
    //   this.destinations.splice(8, 1);
    // }
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // if (this.ets.has_global_config === 1) {
    // } else {
    //   this.destinations.splice(8, 1);
    // }
  }
  logout() {
    LocalStorage.delete("Squares_user");
    location.reload();
  }
  updatePass() {
    const dialogRef = this.dialog.open(UpdatePassComponent);

    dialogRef.afterClosed().subscribe((resultModal) => {
      console.log(resultModal);
      if (resultModal !== "close" || resultModal !== null) {
        // this.finishUpdate(resultModal);
      }
    });
  }
  openModal(param) {
    if (param === "all") {
      const dialogRef = this.dialog.open(NotificationsComponent, {
        data: this.messages,
      });

      dialogRef.afterClosed().subscribe((resultModal) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.finishUpdate(resultModal);
          this.messages = [];
        }
      });
    } else if (param.data.body.title === "100") {
      const dialogRef = this.dialog.open(CommandeDetailModalComponent, {
        data: param.data.body,
      });

      dialogRef.afterClosed().subscribe((resultModal) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.finishUpdate(resultModal);
        }
      });
    } else {
      const dialogRef = this.dialog.open(ColisDetailModalComponent, {
        data: param.data.body,
      });

      dialogRef.afterClosed().subscribe((resultModal) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.finishUpdate(resultModal);
        }
      });
    }
  }
  openMenu() {
    if (this.open === false) {
      this.open = true;
      this.showMenu();
    } else {
      this.open = false;
      // jQuery(".menutext").hide();
      this.hideMenu();
    }
  }
  showMenu() {
    jQuery("#menu").css({
      transform: "translateX(0%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "1",
      display: "block",
    });
  }
  hideMenu() {
    jQuery("#menu").css({
      transform: "translateX(-100%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "0",
      display: "none",
    });
  }
  goToMenu(fragment) {
    document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
  }
  routeMenu(item) {
    console.log(item);
    var dialogRef = null;
    switch (item.route) {
      case "add_stock":
        // dialogRef = this.dialog.open(StockGeneralModalComponent, {
        //   data: { mode: "0" },
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        // if (resultModal !== "close" || resultModal !== null) {
        //   if (resultModal.id_etablissement) {
        //     this.entreprises.push(resultModal);
        //     return;
        //   }
        // }
        // });
        break;
      case "declare_loss":
        this.router.navigateByUrl("sorties_all");
        break;
      case "add_supplier":
        this.router.navigateByUrl("fournisseurs");

        // dialogRef = this.dialog.open(FournisseurModalComponent, {
        //   data: { mode: "0" }
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        //   // if (resultModal !== "close" || resultModal !== null) {
        //   //   if (resultModal.id_etablissement) {
        //   //     this.entreprises.push(resultModal);
        //   //     return;
        //   //   }
        //   // }
        // });
        break;
      case "facturation":
        this.router.navigateByUrl("facturation");

        break;
      case "report":
        this.router.navigateByUrl("facturation");

        break;

      default:
        break;
    }
  }
  contact() {
    const dialogRef = this.dialog.open(CustomerService);
    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
    });
  }
}
