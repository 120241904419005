import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { ImageUtils } from "../../helpers/imageUtils";
import { LocalStorage } from "../../helpers/localSortage";

@Component({
  selector: "app-carres-modal",
  templateUrl: "./carres-modal.component.html",
  styleUrls: ["./carres-modal.component.scss"],
})
export class CarresModalComponent implements OnInit {
  caree: any = {
    image_caree: null,
    mode: "1",
    nom_caree: "",
    id_caree: "",
    description_caree: "",
    id_caree_parent: "",
    carres: [],
    google_product_category: 0,
    taxo: ''
  };
  fileLogo: any = null;
  deps = [];
  type_user = "0";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<CarresModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    switch (data.mode) {
      case "0":
        this.caree.mode = data.mode;
        break;

      case "1":
        this.caree = data;
        break;
      case "2":
        this.caree.mode = data.mode;
        this.caree.carres = data.carres;
        break;

      case "3":
        this.caree = data;
        // this.caree.carres = data.carres;
        break;

      default:
        break;
    }
  }
  ngOnInit() { }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.caree.image_caree = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }

    console.log(this.caree);

    switch (this.caree.mode) {
      case "0":
        var cat = Object.assign({}, this.caree);

        if (this.fileLogo !== null) {
          cat.image_caree = this.fileLogo;
        } else {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour creer un carre",
          });
          return;
        }
        delete cat.mode;
        delete cat.id_caree_parent;
        delete cat.carres;
        delete cat.id_caree;
        delete cat.commissions;
        delete cat.sous_caree;
        delete cat.taxo;

        Loader.load();
        // cat.id_etablissement = this.ets.id_etablissement;

        this.service.post(ENDPOINTS.post_square, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          this.dialogRef.close(data.body);
        });
        break;
      case "1":
        var cat = Object.assign({}, this.caree);

        if (this.fileLogo !== null) {
          cat.image_caree = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer une image pour creer un carre",
          // });
          // return;
        }
        delete cat.mode;
        delete cat.id_caree_parent;
        delete cat.carres;
        delete cat.commissions;
        delete cat.sous_caree;
        delete cat.taxo;

        Loader.load();
        // cat.id_etablissement = this.ets.id_etablissement;

        this.service
          .post(ENDPOINTS.post_update_square, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case "2":
        var cat = Object.assign({}, this.caree);

        if (this.fileLogo !== null) {
          cat.image_caree = this.fileLogo;
        } else {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour creer un carre",
          });
          return;
        }

        if (
          cat.id_caree_parent == null ||
          cat.id_caree_parent == "null" ||
          cat.id_caree_parent == ""
        ) {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez choisir un carre parent",
          });
          return;
        } else {
        }
        delete cat.mode;
        delete cat.carres;
        delete cat.commissions;
        delete cat.sous_caree;
        delete cat.id_caree;
        delete cat.categories_caree;
        delete cat.taxo;

        // cat.id_etablissement = this.ets.id_etablissement;

        Loader.load();

        this.service.post(ENDPOINTS.post_square, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          this.dialogRef.close(data.body);
        });
        break;
      case "3":
        var cat = Object.assign({}, this.caree);

        if (this.fileLogo !== null) {
          cat.image_caree = this.fileLogo;
        } else {
          // DialogBox.showMessage(this.dialog, {
          //   title: "Message",
          //   body: "Veuillez inserer une image pour mettre a  un carre",
          // });
          // return;
        }

        if (
          cat.id_caree_parent == null ||
          cat.id_caree_parent == "null" ||
          cat.id_caree_parent == ""
        ) {
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez choisir un carre parent",
          });
          return;
        } else {
        }
        delete cat.mode;
        delete cat.carres;
        delete cat.commissions;
        delete cat.sous_caree;
        delete cat.categories_caree;
        delete cat.taxo;

        // cat.id_etablissement = this.ets.id_etablissement;

        Loader.load();

        this.service
          .post(ENDPOINTS.post_update_square, cat)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  radioChange(event) {
    console.log(event);
    this.type_user = event.value;
    // if(event.target.value === )
  }
}
