import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { MdcDialog } from "@angular-mdc/web";
import { Loader } from "../../../shared/helpers/loader";
import { ProduitEditModalComponent } from "../../../shared/components/produit-edit-modal/produit-edit-modal.component";
import { STATE } from "../../../shared/model/codes";
import { LocalStorage } from "../../../shared/helpers/localSortage";

@Component({
  selector: "app-article-details",
  templateUrl: "./article-details.component.html",
  styleUrls: ["./article-details.component.scss"],
})
export class ArticleDetailsComponent implements OnInit {
  article_type = {
    id: "1",
    type: "",
  };
  produit: any = {};
  service: any = {};
  commandes = [];
  stocks = [];
  actualWeek: any[] = [];

  actualDate: any;
  date_debut = "2019/12/12";
  date_fin = "2019/12/12";
  display_date_debut = "2019/12/12";
  display_date_fin = "2019/12/12";
  public pieChartType = "bar";
  public pieChartLegend = true;
  // public pieChartData: number[] = [300, 500, 100, 450];
  public pieChartLabels: any[] = [];
  pieData = [{ data: [0], label: "Siri" }];
  articleData = {
    ventes_total: 0,
    ventes_moyenne: 0,
    nombre_ventes: 0,
    data: [],
  };
  ets = JSON.parse(LocalStorage.getItem("currentEts"));

  constructor(
    private route: ActivatedRoute,
    private core: CorePrototype,
    private dialog: MdcDialog
  ) {}

  ngOnInit() {
    Loader.load();
    let token = this.route.snapshot.paramMap.get("id");
    this.actualDay(new Date(), {});
    var tmp = token.split("-");
    this.article_type.id = tmp[1];
    this.article_type.type = tmp[0];
    this.date_debut = this.actualWeek[0].dayDate;
    this.date_fin = this.actualWeek[6].dayDate;
    this.display_date_debut = this.actualWeek[0].dayDate;
    this.display_date_fin = this.actualWeek[6].dayDate;
    if (this.article_type.type === "service") {
      this.core
        .get(ENDPOINTS.get_service, { id_service: this.article_type.id })
        .then((data: any) => {
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_service == "1") {
            data.body.etat_service_bool = true;
          } else {
            data.body.etat_service_bool = false;
          }
          this.service = data.body;
          Loader.stopLoading();
        });
      this.core
        .get(ENDPOINTS.get_orders_by_service, {
          id_service: this.article_type.id,
        })
        .then((data: any) => {
          data.body.forEach((element) => {
            element.date_creation_commande = new Date(
              parseInt(element.date_creation_commande)
            );
          });
          this.commandes = data.body;
          this.commandes.forEach((element: any) => {
            element.etat_commande = STATE[element.etat_commande];
          });
          this.commandes.reverse();
        });
    } else {
      this.core
        .get(ENDPOINTS.get_product, { id_produit: this.article_type.id })
        .then((data: any) => {
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_produit == "1") {
            data.body.etat_produit_bool = true;
          } else {
            data.body.etat_produit_bool = false;
          }
          if (data.body.can_visible_on_marketplace == "1") {
            data.body.can_visible_on_marketplace_bool = true;
          } else {
            data.body.can_visible_on_marketplace_bool = false;
          }
          if (data.body.is_local_product == "1") {
            data.body.is_local_product_bool = true;
          } else {
            data.body.is_local_product_bool = false;
          }
          this.produit = data.body;

          Loader.stopLoading();
        });
      this.core
        .get(ENDPOINTS.get_orders_by_product, {
          id_produit: this.article_type.id,
        })
        .then((data: any) => {
          data.body.forEach((element) => {
            element.date_creation_commande = new Date(
              parseInt(element.date_creation_commande)
            );
          });
          this.commandes = data.body;
          this.commandes.forEach((element: any) => {
            element.etat_commande = STATE[element.etat_commande];
          });
          this.commandes.reverse();
        });
      if (this.ets.ets_level == "1") {
        this.core
          .get(ENDPOINTS.get_product_stocks, {
            id_produit: this.article_type.id,
          })
          .then((data: any) => {
            data.body.forEach((element) => {
              element.date_ajout_stock = new Date(
                parseInt(element.date_ajout_stock)
              );
              element.date_peremption_stock = new Date(
                parseInt(element.date_peremption_stock)
              );
            });
            this.stocks = data.body;
          });
      }
    }
    this.getGraph();
  }
  getGraph() {
    if (this.article_type.type === "service") {
      this.core
        .get(ENDPOINTS.get_recette_service, {
          id_service: this.article_type.id,
          date_debut: new Date(this.date_debut).getTime(),
          date_fin: new Date(this.date_fin).getTime(),
        })
        .then((data: any) => {
          Loader.stoploadingBack();
          this.articleData = data.body;
          console.log("stat Data");
          console.log(data);
          var total = { data: [], label: "Total" };
          var moyenne = { data: [], label: "Moyenne" };
          var ventes = { data: [], label: "Ventes" };
          var labels = [];
          var pData = [];

          this.articleData.data.forEach((element: any) => {
            labels.push(this.convertDate(element.date));
            total.data.push(element.vente_total);
            moyenne.data.push(element.ventes_moyenne);
            ventes.data.push(element.nombre_ventes);
          });
          pData.push(total);
          pData.push(moyenne);
          pData.push(ventes);
          setTimeout(() => {
            this.pieChartLabels = labels;
            this.pieData = pData;
            console.log(labels);
            console.log(pData);
          }, 50);
        });
    } else {
      this.core
        .get(ENDPOINTS.get_recette_produit, {
          id_produit: this.article_type.id,
          date_debut: new Date(this.date_debut).getTime(),
          date_fin: new Date(this.date_fin).getTime(),
        })
        .then((data: any) => {
          this.articleData = data.body;
          console.log("stat Data");
          console.log(data);
          var total = { data: [], label: "Total" };
          var moyenne = { data: [], label: "Moyenne" };
          var ventes = { data: [], label: "Ventes" };
          var labels = [];
          var pData = [];

          this.articleData.data.forEach((element: any) => {
            labels.push(this.convertDate(new Date(element.date)));
            total.data.push(element.vente_total);
            moyenne.data.push(element.ventes_moyenne);
            ventes.data.push(element.nombre_ventes);
          });
          pData.push(total);
          pData.push(moyenne);
          pData.push(ventes);
          setTimeout(() => {
            this.pieChartLabels = labels;
            this.pieData = pData;
            console.log(labels);
            console.log(pData);
          }, 50);
        });
    }
  }
  editOrder(order) {
    // ets.mode = '1';
    const dialogRef = this.dialog.open(CommandeDetailModalComponent, {
      data: order,
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal === "close" || resultModal === null) {
        // alert('Closed');
        return;
      }
    });
  }
  edit() {
    if (this.article_type.type === "service") {
      this.service.mode = "1";
      const dialogRef = this.dialog.open(ProduitEditModalComponent, {
        data: this.service,
      });

      dialogRef.afterClosed().subscribe((resultModal: any) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          this.service = resultModal;
        }
      });
    } else {
      this.produit.mode = "1";
      const dialogRef = this.dialog.open(ProduitEditModalComponent, {
        data: this.produit,
      });

      dialogRef.afterClosed().subscribe((resultModal: any) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.produit = resultModal
        }
      });
    }
  }
  onChange(event) {
    console.log(event);

    if (this.article_type.type === "service") {
      var local_article: any = {
        id_service: this.service.id_service,
      };
      // if (event.checked === false) {
      //   this.service.etat_service = "0";
      // } else {
      //   this.service.etat_service = "1";
      // }

      if (this.service.can_be_reserved_bool == true) {
        local_article.can_be_reserved = 1;
      } else {
        local_article.can_be_reserved = 0;
      }
      if (this.service.can_be_delivered_bool == true) {
        local_article.can_be_delivered = 1;
      } else {
        local_article.can_be_delivered = 0;
      }
      if (this.service.for_kitchen == true) {
        local_article.for_kitchen = 1;
      } else {
        local_article.for_kitchen = 0;
      }
      if (this.service.etat_service_bool == true) {
        local_article.etat_service = 1;
      } else {
        local_article.etat_service = 0;
      }

      Loader.load();
      this.core
        .post(ENDPOINTS.post_update_service, local_article)
        .then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_service == "1") {
            data.body.etat_service_bool = true;
          } else {
            data.body.etat_service_bool = false;
          }
          this.service = data.body;
          // this.dialogRef.close(data.body);
        });
    } else {
      // if (event.checked === false) {
      //   this.produit.etat_produit = "0";
      // } else {
      //   this.produit.etat_produit = "1";
      // }
      var local_article: any = {
        id_produit: this.produit.id_produit,
      };
      // if (event.checked === false) {
      //   this.service.etat_service = "0";
      // } else {
      //   this.service.etat_service = "1";
      // }

      if (this.produit.can_be_reserved_bool == true) {
        local_article.can_be_reserved = 1;
      } else {
        local_article.can_be_reserved = 0;
      }
      if (this.produit.can_be_delivered_bool == true) {
        local_article.can_be_delivered = 1;
      } else {
        local_article.can_be_delivered = 0;
      }
      if (this.produit.for_kitchen == true) {
        local_article.for_kitchen = 1;
      } else {
        local_article.for_kitchen = 0;
      }
      if (this.produit.etat_produit_bool == true) {
        local_article.etat_produit = 1;
      } else {
        local_article.etat_produit = 0;
      }
      if (this.produit.can_visible_on_marketplace_bool == true) {
        local_article.can_visible_on_marketplace = 1;
      } else {
        local_article.can_visible_on_marketplace = 0;
      }
      if (this.produit.is_local_product_bool == true) {
        local_article.is_local_product = 1;
      } else {
        local_article.is_local_product = 0;
      }
      Loader.load();
      // tslint:disable-next-line: max-line-length
      this.core
        .post(ENDPOINTS.post_update_product, local_article)
        .then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          if (data.body.for_kitchen == "1") {
            data.body.for_kitchen_bool = true;
          } else {
            data.body.for_kitchen_bool = false;
          }

          if (data.body.can_be_reserved == "1") {
            data.body.can_be_reserved_bool = true;
          } else {
            data.body.can_be_reserved_bool = false;
          }
          if (data.body.can_be_delivered == "1") {
            data.body.can_be_delivered_bool = true;
          } else {
            data.body.can_be_delivered_bool = false;
          }
          if (data.body.etat_produit == "1") {
            data.body.etat_produit_bool = true;
          } else {
            data.body.etat_produit_bool = false;
          }
          if (data.body.can_visible_on_marketplace == "1") {
            data.body.can_visible_on_marketplace_bool = true;
          } else {
            data.body.can_visible_on_marketplace_bool = false;
          }
          if (data.body.is_local_product == "1") {
            data.body.is_local_product_bool = true;
          } else {
            data.body.is_local_product_bool = false;
          }
          this.produit = data.body;
          // this.dialogRef.close(data.body);
        });
    }
  }
  newProductStock() {
    // var dialogRef = this.dialog.open(StockModalComponent, {
    //   data: { id_produit: this.produit.id_produit, mode: "0" },
    // });
    // dialogRef.afterClosed().subscribe((resultModal: any) => {
    //   console.log(resultModal);
    //   if (resultModal !== "close" || resultModal !== null) {
    //     // alert('Closed');
    //     // this.entreprises.push(resultModal);
    //     this.stocks.push(resultModal);
    //     if (this.produit.quantite_en_stock === NaN) {
    //       this.produit.quantite_en_stock = 0;
    //     } else {
    //       if (
    //         !resultModal.quantite_stock ||
    //         resultModal.quantite_stock === NaN
    //       ) {
    //       } else {
    //         this.produit.quantite_en_stock =
    //           parseInt(this.produit.quantite_en_stock) +
    //           parseInt(resultModal.quantite_stock);
    //       }
    //     }
    //     return;
    //   }
    // });
  }
  updateProductStock(stock) {
    // console.log(stock);
    stock.mode = "1";

    // var dialogRef = this.dialog.open(StockModalComponent, {
    //   data: stock,
    // });

    // dialogRef.afterClosed().subscribe((resultModal: any) => {
    //   console.log(resultModal);
    //   if (resultModal !== "close" || resultModal !== null) {
    //     // alert('Closed');
    //     // this.entreprises.push(resultModal);
    //     var index = this.stocks.findIndex(
    //       (item) => item.id_produit == resultModal.id_produit
    //     );
    //     this.stocks[index] = resultModal;
    //     this.produit.quantite_en_stock =
    //       parseInt(this.produit.quantite_en_stock) +
    //       parseInt(this.stocks[index].final);
    //     delete this.stocks[index].final;
    //     return;
    //   }
    // });
  }
  actualDay(curr, option) {
    // var tmp=[];
    // var curr2 = new Date(curr);
    if (typeof curr !== "object") {
      curr = new Date(curr);
      this.actualDate = this.convertDate(
        new Date(curr.setDate(curr.getDate()))
      );
    }
    curr.setDate(curr.getDate() - curr.getDay());

    for (var i = 0; i < 7; i++) {
      this.actualWeek[i] = { dayDate: this.convertDate(new Date(curr)) };
      curr.setDate(curr.getDate() + 1);
      var localDate = new Date(this.actualWeek[i].dayDate);
      var stamp = localDate.getTime();
      this.actualWeek[i].timestamp = stamp;
    }
  }
  convertDate(date) {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  updateGraph() {
    Loader.loadBack();
    this.getGraph();
  }
}
