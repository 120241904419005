import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { ArticlesComponent } from "./pages/articles/articles.component";
import { ArticleDetailsComponent } from "./pages/article-details/article-details.component";
import { MarchandsComponent } from "./pages/marchands/marchands.component";
import { CarresComponent } from "./pages/carres/carres.component";
import { ClientDetailsComponent } from "./pages/client-details/client-details.component";
import { ClientsComponent } from "./pages/clients/clients.component";
import { DetailsUpdateComponent } from "./pages/details-update/details-update.component";
import { CritereProduitComponent } from "./pages/critere_produit/critere-produit.component";
import { NewsletterComponent } from "./pages/newsletter/newsletter.component";
import { SlideImagesComponent } from "./pages/slide-images/slide-images.component";
import { MarchandDetailsComponent } from "./pages/marchand-details/marchand-details.component";
import { NotifsComponent } from './pages/notifs/notifs.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { PayLinkComponent } from './pages/pay-link/pay-link.component';

const CoreRoutes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "marchands", component: MarchandsComponent },
  { path: "commandes", component: OrdersComponent },
  { path: "articles", component: ArticlesComponent },
  { path: "carres", component: CarresComponent },
  { path: "client_details/:id", component: ClientDetailsComponent },
  { path: "clients", component: ClientsComponent },
  { path: "article/:id", component: ArticleDetailsComponent },
  { path: "update_article/:id", component: DetailsUpdateComponent },
  { path: "create_article", component: DetailsUpdateComponent },
  { path: "critere_produits", component: CritereProduitComponent },
  { path: "marchands", component: MarchandsComponent },
  { path: "newsletter", component: NewsletterComponent },
  { path: "marchand_details/:id", component: MarchandDetailsComponent },
  { path: "slide_images", component: SlideImagesComponent },
  { path: "notifs", component: NotifsComponent },
  { path: "versions", component: VersionsComponent },
  { path: "promotions", component: PromotionsComponent },
  { path: "pay-link", component: PayLinkComponent },
];

@NgModule({
  imports: [RouterModule.forChild(CoreRoutes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
