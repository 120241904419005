import { Component, OnInit, Inject } from '@angular/core';
import { CorePrototype } from '../../../core/services/core.prototype';
import { Router } from '@angular/router';
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from '@angular-mdc/web';
import { ENDPOINTS } from '../../model/endpoints';
import { NgForm } from '@angular/forms';
import { Loader } from '../../helpers/loader';
import { DialogBox } from '../../helpers/dialogBox';
import { STATE_PACKET } from '../../model/codes';

@Component({
  selector: 'app-colis-detail-modal',
  templateUrl: './colis-detail-modal.component.html',
  styleUrls: ['./colis-detail-modal.component.scss']
})
export class ColisDetailModalComponent implements OnInit {

  colis: any = {

  }
  etat = ''
  constructor(private service: CorePrototype, private dialog: MdcDialog, private route: Router, public dialogRef: MdcDialogRef<ColisDetailModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    this.colis = data;
      this.etat = STATE_PACKET[data.etat_course];
  }

  ngOnInit() {

  }
  submit(params: NgForm) {

  }
  updateCommande(value) {
    // if(value === '3')
    if (this.colis.cout_transport.length < 1) {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer un cout de transport" })
      return;
    }
    this.colis.etat_course = value;
    // this.commande.firebase_user_token = '';
    Loader.load();
    // this.service.post(ENDPOINTS.post_update_packet, { firebase_user_token: '', etat_course: value, id_course: this.colis.id_course, cout_transport: this.colis.cout_transport, commentaire_course:this.colis.commentaire_course }).then((user: any) => {
    //   Loader.stopLoading();
    //   console.log(user);
    //   this.dialogRef.close(user.body);
    // })
  }


}
