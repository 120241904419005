import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { CorePrototype } from "../../services/core.prototype";
import { ENDPOINTS } from "../../../shared/model/endpoints";
import { MdcDialog, MdcDialogRef } from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "../../../shared/components/commande-detail-modal/commande-detail-modal.component";
import { STATE } from "../../../shared/model/codes";
import { Loader } from "../../../shared/helpers/loader";
import { OrderDetailsModal } from "../../../shared/components/order-details-modal/order-details-modal";
import { CarresModalComponent } from "../../../shared/components/carres-modal/carres-modal.component";
import { CategorieCareeModalComponent } from "../../../shared/components/categorie_caree_modal/categorie-caree-modal.component";

@Component({
  selector: "app-carres",
  templateUrl: "./carres.component.html",
  styleUrls: ["./carres.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CarresComponent implements OnInit {
  carres = [];
  sous_carres = [];
  categorie_caree = [];
  term = "";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(private service: CorePrototype, private dialog: MdcDialog) { }

  ngOnInit() {
    Loader.load();
    this.service.get(ENDPOINTS.get_squares_with_sub).then((data: any) => {
      // data.body.forEach((element) => {
      //   element.date_creation_commande = new Date(
      //     parseInt(element.date_creation_commande)
      //   );
      // });

      data.body.forEach((caree) => {
        caree.commissions = [];
        caree.taxo = caree.nom_caree;
        this.sous_carres.push(caree.sous_caree);
        caree.sous_caree.forEach((sous_caree) => {
          sous_caree.taxo = caree.taxo + ' > ' + sous_caree.nom_caree;
          sous_caree.categories_caree.forEach((categorie_caree) => {
            categorie_caree.taxo = sous_caree.taxo + ' > ' + categorie_caree.nom_categorie_caree;
            caree.commissions.push(categorie_caree.perc_commission);
          });
        });
      });
      this.carres = data.body;
      Loader.stopLoading();
    });
  }

  edit(element, label) {
    // ets.mode = '1';
    var dialogRef: MdcDialogRef<any> = null;
    switch (label) {
      case "caree":
        element.mode = "1";
        dialogRef = this.dialog.open(CarresModalComponent, {
          data: element,
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;
      case "sous_caree":
        element.mode = "3";
        element.carres = this.carres;
        dialogRef = this.dialog.open(CarresModalComponent, {
          data: element,
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;
      case "categorie_caree":
        element.mode = "1";
        element.carres = this.carres;
        dialogRef = this.dialog.open(CategorieCareeModalComponent, {
          data: element,
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;

      default:
        break;
    }
  }
  new(label) {
    var dialogRef: MdcDialogRef<any> = null;
    switch (label) {
      case "caree":
        dialogRef = this.dialog.open(CarresModalComponent, {
          data: { mode: "0" },
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;
      case "sous_caree":
        // element.carres = this.carres;
        dialogRef = this.dialog.open(CarresModalComponent, {
          data: { mode: "2", carres: this.carres },
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;
      case "categorie_caree":
        dialogRef = this.dialog.open(CategorieCareeModalComponent, {
          data: { mode: "0", carres: this.carres },
        });
        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
        });
        break;

      default:
        break;
    }
  }
  searcher() {
    var term = this.term.toUpperCase();
    // for (let index = 0; index < this.orders.length; index++) {
    // this.orders.forEach((element) => {
    //   if (element.matricule_commande.toUpperCase().indexOf(term) > -1) {
    //     // console.log(element.matricule_commande.toUpperCase().indexOf(term));
    //     element.show = 1;
    //   } else {
    //     element.show = 0;
    //   }
    // });
    //   this.departments[index].services.forEach(element => {
    //     if (element.nom_service.toUpperCase().indexOf(term) > -1) {
    //       // console.log(element.matricule_commande.toUpperCase().indexOf(term));
    //       element.show = 1;
    //     } else {
    //       element.show = 0;
    //     }
    //   });
    // }
  }
}
