import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/components/shared.module";
import { PipeModule } from "../shared/pipes/pipe.module";
import { CoreRoutingModule } from "./core-routing.module";
// import { HomeComponent } from "./pages/home/home.component";
import { CorePrototype } from "./services/core.prototype";

import { HomeComponent } from "./pages/home/home.component";

import {
  MDCDataTableModule,
  MdcIconModule,
  MdcTabBarModule,
  MdcTabScrollerModule,
  MdcTabIndicatorModule,
  MdcButtonModule,
  MdcSwitchModule,
  MdcCardModule,
  MdcTextFieldModule,
  MdcTopAppBarModule,
  MdcTypographyModule,
  MdcFabModule,
  MdcListModule,
  MdcSelectModule,
  MdcCheckboxModule,
  MdcSliderModule,
  MdcRippleModule,
  MdcRadioModule, MdcChipsModule
} from "@angular-mdc/web";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

// import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { ChartsModule } from "ng2-charts";
import { OrdersComponent } from "./pages/orders/orders.component";
import { ArticlesComponent } from "./pages/articles/articles.component";
import { ArticleDetailsComponent } from "./pages/article-details/article-details.component";
import { CarresComponent } from "./pages/carres/carres.component";
import { ClientDetailsComponent } from "./pages/client-details/client-details.component";
import { ClientsComponent } from "./pages/clients/clients.component";
import { CritereProduitComponent } from "./pages/critere_produit/critere-produit.component";
import { MarchandDetailsComponent } from "./pages/marchand-details/marchand-details.component";
import { NewsletterComponent } from "./pages/newsletter/newsletter.component";
import { SlideImagesComponent } from "./pages/slide-images/slide-images.component";
import { DetailsUpdateComponent } from "./pages/details-update/details-update.component";
import { MarchandsComponent } from "./pages/marchands/marchands.component";
import { NotifsComponent } from './pages/notifs/notifs.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { PayLinkComponent } from './pages/pay-link/pay-link.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule.forRoot(),
    CoreRoutingModule,
    SharedModule,
    MDCDataTableModule,
    MdcIconModule,
    MdcTabBarModule,
    MdcTabScrollerModule,
    MdcTabIndicatorModule,
    MdcButtonModule,
    MdcSwitchModule,
    ChartsModule,
    MdcCardModule,
    MdcTextFieldModule,
    MdcTopAppBarModule,
    MdcTypographyModule,
    MdcFabModule,
    MdcListModule,
    MdcSelectModule,
    MdcCheckboxModule,
    MdcSliderModule,
    CKEditorModule,
    MdcRippleModule,
    MdcRadioModule,
    MdcChipsModule,
    TableModule,
    InputTextModule,
    DialogModule,
    InputTextareaModule
  ],
  declarations: [
    HomeComponent,
    CarresComponent,
    ArticlesComponent,
    ArticleDetailsComponent,
    ClientDetailsComponent,
    ClientsComponent,
    CritereProduitComponent,
    MarchandsComponent,
    NewsletterComponent,
    OrdersComponent,
    SlideImagesComponent,
    DetailsUpdateComponent,
    MarchandDetailsComponent,
    NotifsComponent,
    VersionsComponent,
    PromotionsComponent,
    PayLinkComponent,
  ],

  providers: [CorePrototype],
})
export class CoreModule { }
